import React, { useState } from 'react'

import style from '../style.module.css';

import Hidden from '@material-ui/core/Hidden';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import ItensWeb from './ItensWeb';
import ItensMobile from './ItensMobile';

const DadosItems = ({ order }) => {
    const [collapse, setCollapse] = useState([]);

    const listaItens = order.itens;
    const valorAdicional = order.ValorAdicional ?? 0;
    let valorTotalItens = 0;
    let valorTotalDescontosItens = order.ValorDesconto ?? 0;
    
    const somaValorTotal = lista => lista.reduce((prevValue, currentValue) => prevValue + currentValue.PrecoTotal, 0);
    const somaTotalDescontosItens = lista => lista.reduce((prevValue, currentValue) => prevValue + currentValue.ValorDesconto, 0);
    const formatarValor = valor => valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

    const handleCollapse = id => {
        const index = collapse.indexOf(id);
        
        if(index === -1) {
            setCollapse([...collapse, id]);
        } else {
            const newCollapse = [...collapse];
            newCollapse.splice(index, 1)
            setCollapse([...newCollapse]);
        }
    }
    const collapsed = id => collapse.indexOf(id) > -1;

    const collapseFunctions = {
        collapse,
        setCollapse,
        handleCollapse,
        collapsed
    }

    if(listaItens){
        valorTotalItens = somaValorTotal(listaItens);
        valorTotalDescontosItens += somaTotalDescontosItens(listaItens);
    }

    return (
        <Card className={style.orderCard}>
            <CardHeader title="Itens do Pedido" />
            <CardContent>

                <Hidden xsDown>
                    <ItensWeb 
                        listaItens={listaItens} 
                        valorAdicional={valorAdicional}
                        valorTotalItens={valorTotalItens}
                        valorTotalDescontosItens={valorTotalDescontosItens}
                        formatarValor={formatarValor} 
                        collapseFunctions={collapseFunctions}
                    />
                </Hidden>

                <Hidden smUp>
                    <ItensMobile 
                        listaItens={listaItens} 
                        valorAdicional={valorAdicional}
                        valorTotalItens={valorTotalItens}
                        valorTotalDescontosItens={valorTotalDescontosItens}
                        formatarValor={formatarValor} 
                        collapseFunctions={collapseFunctions}
                    />
                </Hidden>

            </CardContent>
        </Card>   
    )
}

export default React.memo(DadosItems);
