import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import style from './style.module.css';

import { Creators as ConstCreators } from '../../main/ducks/constants';
import { sendPayment } from '../../main/services/payOrder';

import arrayClient from '../../customers/customersList';

import DadosPedido from './DadosPedido';
import DadosItens from './DadosItens';
import DadosEntrega from './DadosEntrega';
import DadosPagamento from './DadosPagamento';
import Success from './Success';
import QrCode from './FormasPagto/DadosPix/QrCode';
import ToastMessage from '../Common/msgToast';

import { Grid, Button } from '@material-ui/core';

import { getPaymentData, getOrderDetails, getPagseguroSession } from '../../main/services/onlinePay';
import selectGateway from './functions/gatewayFunctions';
import functions from './functions';
import validators from '../../validates/requireValidate';
import { phoneFormat } from '../../validates/phoneValidate';
import { cpfFormat, validarCpf } from '../../validates/cpfValidate'
import { convertUrlToClient } from '../../util/convertUrlToClient';


let initialCustomer = {
    cpf: '',
    phone: '',
    name: '',
    email: ''
};
let initialAddress = {
    id: '',
    code: '',
    address: '',
    number: '',
    description: '',
    district: '',
    city: '',
    state: ''
}
let initialCreditCard = {
    cardCode: '',
    cardType: '',
    cardNumber: '',
    expireDate: '',
    birthDay: '',
    cvc: '',
    cardOwner: '',
    cpf: ''
};

const fakeInitialCustomer = {
    cpf: '01234567890',
    phone: '11986525692',
    name: 'Ambiente Comercial',
    email: 'comercial@fagrontech.com.br'
};
const fakeInitialAddress = {
    id: '89809',
    code: '',
    address: '',
    number: '',
    description: '',
    district: '',
    city: '',
    state: ''
}
const fakeInitialCreditCard = {
    cardCode: '500',
    cardType: 'visa',
    cardNumber: '4539950300417761',
    expireDate: '06/30',
    birthDay: '2020-01-01',
    cvc: '123',
    cardOwner: 'Ambiente Comercial',
    cpf: '01234567890',
    phone: '11986525692',
    email: 'comercial@fagrontech.com.br'
};
const initialPaymentValue = {
    qtdParcelas: 1,
    valorParcela: 0,
    valorMdr: 0,
    valorFee: 0,
    valorTotal: 0,
    valorLiquido: 0
};

const payment = {
    TenantId: '',
    PedidoId: '',
    registroPedido: '',
    CanalId: 3, // código para loja virtual 
    FormaDePagamento: 2, //3 - boleto / 2 - cartão
    dadosCliente: { ...initialCustomer },
    clientePagto: { ...initialCustomer},
    endereco: { ...initialAddress },
    cartaoCredito: { ...initialCreditCard },
    qtdParcelas: initialPaymentValue.qtdParcelas,
    valorParcela: initialPaymentValue.valorParcela,
    valorTotal: initialPaymentValue.valorTotal,
    valorLiquido: initialPaymentValue.valorLiquido,
    PagSeguroSenderHash: null,
    PagSeguroCreditCardToken: null,
    fingerPrintId: null
};

let dadosViewPix = {
    pedido: {
        Id: 0,
        NumeroPedido: 0,
        DataCriacao: "",
        NomeStatusPedido: "",
        Retirada: {
            DESCRFIL: "",
        },
        TempoExpiracaoSegundos: 0
    },
    urlBoleto: null,
    qrCode: "",
    linhaDigitavel: ""
}

const url = new URL(window.location.href);
let urlCustomer = convertUrlToClient(url.origin);

arrayClient.forEach(client => {
    if (client?.client === urlCustomer?.client && client.hasOwnProperty('fakeCard') && client.fakeCard) {
        initialCustomer = fakeInitialCustomer;
        initialCreditCard = fakeInitialCreditCard;
        initialAddress = fakeInitialAddress;
    }
})


const OnlinePay = () => {
    const [order, setOrder] = useState({});
    const [formaPagto, setFormaPagto] = useState('cartao');
    const [customer, setCustomer] = useState(initialCustomer);
    const [address, setAddress] = useState(initialAddress);
    const [creditCard, setCreditCard] = useState(initialCreditCard);
    const [paymentValue, setPaymentValue] = useState(initialPaymentValue);
    const [response, setResponse] = useState({});

    const { clientRequest } = useSelector(state => state.constants);

    const dispatch = useDispatch();
    const { registro } = useParams();

    const fingerPrintId = useRef(null);
    const formaPagamento = useRef(null);
    const provedorPagto = useRef(null);

    const [showPixPage, setshowPixPage] = useState(false);

    const pagseguroSessionId = useRef(null);

    useEffect(() => {
        dispatch(ConstCreators.setLoading(true));       
        registro &&
            clientRequest.tenantId &&
            clientRequest.urlApi &&
            getOrderDetails(clientRequest, registro)
                .then(resp => {
                    setOrder(resp);
                    
                    getPaymentData(clientRequest,resp.FilialId)
                        .then(resp => {
                            provedorPagto.current = resp.ProvedorPagamento;
                            formaPagamento.current = resp.FormaPagamento;
                            formaPagamento.current = JSON.parse(formaPagamento.current).FormaDePagamento;
                            fingerPrintId.current = selectGateway(provedorPagto.current, resp.AmbienteProducao);

                            setFormaPagto('cartao');

                            if (!formaPagamento?.current?.Cartao?.Ativo) {
                                if (formaPagamento?.current?.Boletos?.Ativo) {
                                    setFormaPagto('boleto');
                                } else if (formaPagamento?.current?.Pix?.Ativo) {
                                    setFormaPagto('pix');
                                }
                            }

                            resp.ProvedorPagamento === 'P' &&
                                getPagseguroSession(clientRequest)
                                    .then(resp => {
                                        pagseguroSessionId.current = resp.SessionId;
                                    })
                        })
                        .catch(e => {
                            console.error(e);
                        })
                        .finally(() => {
                            dispatch(ConstCreators.setLoading(false));
                        })
                })
                .catch(e => {
                    console.error(e);
                })
                .finally(() => {
                    dispatch(ConstCreators.setLoading(false));
                })

    }, [registro, clientRequest]);

    const inputHandleFormaPagto = e => setFormaPagto(e.target.value);

    const handleCustomer = e => {
        const { name, value } = e.target;
        setCustomer({ ...customer, [name]: value });
    };

    const handleAddress = e => {
        const { name, value } = e.target;
        setAddress({ ...address, [name]: value });
    };
    const handleExistAddress = data => setAddress({ ...initialAddress, id: data });
    const handleAddressByZip = data => {
        if (data.cep) {
            const newData = {
                ...initialAddress,
                id: 0,
                code: data.cep,
                address: data.logradouro,
                description: data.complemento,
                district: data.bairro,
                city: data.localidade,
                state: data.uf
            };

            setAddress({ ...address, ...newData });
        }
    }

    const inputHandleCreditCard = e => {
        const { name, value } = e.target;

        if (name === 'cardNumber' && value.length >= 4) {
            const bandeira = window.Payment.fns.cardType(value);

            const code = functions.getCardCode(bandeira, formaPagamento.current.Cartoes);
            setCreditCard({ ...creditCard, cardCode: code, cardNumber: value, cardType: bandeira });
        } else if (name === 'cardOwner') {
            setCreditCard({ ...creditCard, [name]: value });
        } else {
            setCreditCard({ ...creditCard, [name]: value.trim() });
        }
    }

    const handleInstalments = data => {
        data &&
            setPaymentValue({
                qtdParcelas: data.qtdParcelas,
                valorParcela: data.valorParcela,
                valorMdr: data.valorMdr,
                valorFee: data.valorFee,
                valorTotal: data.valorTotal
            });
    }

    const ObterFormaPagamentoEnum = (formaPagto) => {
        switch (formaPagto) {
            case 'boleto': return 3;
            case 'pix': return 5;
            default: return 2; //cartao de credito
        }
    }

    const handleShowPix = (e) => {
        setResponse(dadosViewPix)
        const dadosPix = JSON.parse(order.dadosPix);
        dadosViewPix.qrCode = dadosPix.QrCode;
        dadosViewPix.linhaDigitavel = dadosPix.QrCodeTexto
        dadosViewPix.pedido.Retirada.DESCRFIL = order.filial;
        dadosViewPix.pedido.DataCriacao = order.DataCriacao;
        dadosViewPix.pedido.NomeStatusPedido = order.StatusPedidoDescricao;
        dadosViewPix.TempoExpiracaoSegundos = e;
        setResponse(dadosViewPix)
        setshowPixPage(true)
    }

    const handleFinalizar = async () => {
        if (!validators()) {
            try {
                dispatch(ConstCreators.setLoading(true));

                payment.TenantId = clientRequest.tenantId;
                payment.PedidoId = order.Id;
                payment.registroPedido = registro;
                payment.FormaDePagamento = ObterFormaPagamentoEnum(formaPagto);

                payment.dadosCliente = { ...customer };
                
                payment.dadosCliente.phone = phoneFormat(payment.dadosCliente.phone);
                payment.dadosCliente.cpf = cpfFormat(payment.dadosCliente.cpf.trim());

                if (payment.dadosCliente.cpf !== '' && !validarCpf(payment.dadosCliente.cpf))
                    throw Error('CPF do cliente Inválido!');

                payment.dadosCliente.name = payment.dadosCliente.name.toUpperCase();

                payment.endereco = { ...address };
                payment.endereco.id = (parseInt(payment.endereco.id) === 0 ? '' : payment.endereco.id);

                payment.cartaoCredito = { ...creditCard };

                if (payment.FormaDePagamento === 2) {
                    let dataNascimentoValidar = payment.cartaoCredito.birthDay.split('/').reverse().join('-');

                    if (dataNascimentoValidar >= functions.getToday())
                        throw Error('Data de Nascimento não pode ser igual ou maior que a data Atual!');

                    payment.cartaoCredito.cpf = cpfFormat(payment.cartaoCredito.cpf);
                    if (!validarCpf(payment.cartaoCredito.cpf))
                        throw Error('CPF do proprietário do cartão Inválido!');

                    if (payment.cartaoCredito.expireDate.length === 5) {
                        // Quando o campo de validade for preenchido com o ano completo
                        payment.cartaoCredito.expireDate = `${creditCard.expireDate.split('/')[0]}/20${creditCard.expireDate.split('/')[1]}`;
                    }
                    creditCard.expireDate = payment.cartaoCredito.expireDate;
                }

                payment.valorLiquido = parseFloat(order.ValorLiquido);
                payment.qtdParcelas = parseInt(paymentValue.qtdParcelas);
                payment.valorMdr = parseInt(paymentValue.valorMdr);
                payment.valorFee = parseInt(paymentValue.valorFee);
                payment.valorParcela = paymentValue.valorParcela > 0 ? parseFloat(paymentValue.valorParcela) : parseFloat(order.ValorLiquido);
                payment.valorTotal = parseFloat(paymentValue.valorTotal) > 0 ? parseFloat(paymentValue.valorTotal) : parseFloat(order.ValorLiquido);

                if (provedorPagto.current === 'P' && payment.FormaDePagamento === 2) {
                    try {
                        payment.PagSeguroSenderHash = functions.obterPagseguroSenderHash();
                        payment.PagSeguroCreditCardToken = await functions.obterPagseguroCardToken(pagseguroSessionId.current, creditCard);
                    } catch (e) {
                        console.error(e)
                    };
                }

                if (payment.FormaDePagamento === 2) { //cartao de credito
                    payment.clientePagto = {
                        cpf: cpfFormat(payment.cartaoCredito.cpf.trim()),
                        phone: phoneFormat(payment.cartaoCredito.phone),
                        name: payment.cartaoCredito.cardOwner,
                        email: payment.cartaoCredito.email
                    }                  
                }

                payment.fingerPrintId = fingerPrintId.current;
                const response = await sendPayment(clientRequest.urlApi, payment);

                setResponse(response);

                window.scrollTo(0, 0);

                if (formaPagto === 'pix') {
                    return <QrCode data={response} order={order} />
                }

                ToastMessage('Pedido gerado com Sucesso!', true);

            } catch (error) {
                ToastMessage(`Erro ao tentar pagar o pedido!\r\n${error.message}`, false);
                console.error(error);

            } finally {
                dispatch(ConstCreators.setLoading(false));
            }

        }
    };

    const validarRetorno = () => {
        const addressFunctions = { address, handleAddress, handleExistAddress, handleAddressByZip };
        const cardFunctions = {
            cardData: creditCard,
            selectedInstallment: paymentValue.qtdParcelas,
            handleInstalments: handleInstalments,
            inputHandleCreditCard: inputHandleCreditCard,
        };       

        if (order) {
            return (
                <>
                    <Grid item xs={11} md={8}>
                        {order.NumeroPedido && <h2 className={style.titulo}>Pedido #{order.NumeroPedido}</h2>}
                    </Grid>

                    <Grid item xs={11} md={8}>
                        <DadosPedido data={order} onChangePix={(e) => { handleShowPix(e) }} client={clientRequest} />
                    </Grid>

                    <Grid item xs={11} md={8}>
                        <DadosItens order={order} />
                    </Grid>

                    <Grid item xs={11} md={8} >
                        <DadosEntrega data={order} />
                        <br />
                    </Grid>
                    {
                        order.TipoLiberacaoPagamento_Id > 0 &&
                        ![3, 4, 6, 9, 11].includes(order.StatusPedido_Id) &&
                        <Grid item xs={11} md={8}>
                            <DadosPagamento
                                configPagto={formaPagamento.current}
                                order={order}
                                formaPagto={formaPagto}
                                handleCustomer={handleCustomer}
                                addressFunctions={addressFunctions}
                                cardFunctions={cardFunctions}
                                inputHandleFormaPagto={inputHandleFormaPagto}
                                handdleSubmit={handleFinalizar}                               
                            />
                        </Grid>
                    }
                </>
            );
        } else {
            return (
                <Grid item xs={10}>
                    <h2>Seu pedido está sendo processado. Tente novamente mais tarde.</h2>
                </Grid>
            )
        }
    }

    if (response && response.tenantId && formaPagto !== 'pix' && !showPixPage)
        return <Success data={response} />

    if (response && response.tenantId && formaPagto === 'pix' || showPixPage)
        return <QrCode data={response} order={order} />

    return (
        <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
            {validarRetorno()}
        </Grid>
    )
}

export default OnlinePay;
