exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style_titulo__1ZJZr {\n    text-align: left;\n    border-bottom: 3px solid black;\n}\n\n.style_sendButton__26ENj { \n    text-align: right; \n    margin-bottom: 10px !important;\n}\n\n.style_subHeaderCard__1Ot-n { font-size: 16px; }\n.style_subHeaderInfo__1u0qe { color:black; }\n\n\n.style_itemRow__3sA8c {\n    flex-grow: 1\n}\n.style_phoneNumber__3mXS0 {\n    flex-grow: 1;\n    margin-left: 10px;\n}\n\n.style_successContainer__1BU_5 {\n    display: flex;\n    flex-direction: column;\n    width: 70%;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n.style_divLinhaDigitavel__1Ep5k { \n    display: none;\n    width: 100% \n}\n.style_divShowBoleto__3Xfnb { width: 100% }\n\n@media (max-width: 1050px) {\n    .style_successContainer__1BU_5 { width: 90%; }\n}\n\n@media (max-width: 750px) {\n    .style_divLinhaDigitavel__1Ep5k {display: block; }\n    .style_divShowBoleto__3Xfnb { display: none; }\n}\n\n@media (max-width: 600px) {\n    .style_phoneNumber__3mXS0 {\n        min-width: 0;\n        margin-left: 0;\n        width: 100%;\n    }\n\n    .style_growRowOnMobile__3Cxyb { flex-grow: 1 }\n}\n", ""]);

// Exports
exports.locals = {
	"titulo": "style_titulo__1ZJZr",
	"sendButton": "style_sendButton__26ENj",
	"subHeaderCard": "style_subHeaderCard__1Ot-n",
	"subHeaderInfo": "style_subHeaderInfo__1u0qe",
	"itemRow": "style_itemRow__3sA8c",
	"phoneNumber": "style_phoneNumber__3mXS0",
	"successContainer": "style_successContainer__1BU_5",
	"divLinhaDigitavel": "style_divLinhaDigitavel__1Ep5k",
	"divShowBoleto": "style_divShowBoleto__3Xfnb",
	"growRowOnMobile": "style_growRowOnMobile__3Cxyb"
};