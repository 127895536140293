import React from 'react';

import './styles.css';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';

import CelPhoneField from '../../../Common/Form/CelPhoneField';
import CpfField from '../../../Common/Form/CpfField';

const DadosBoleto = ({ order, handleCustomer }) => {
    return (
        <>
            {
                order.Cliente_Id &&
                    <div id="divDadosPagador" style={{ display: 'flex', flexFlow: 'row wrap', padding: '10px', width: '100%' }}>
                        <div style={{ width: '100%' }}>
                            <FormLabel component="legend">Dados pagador</FormLabel>
                        </div>
                        <div>
                            <CpfField inputHandle={handleCustomer} />
                        </div>

                        <div>
                            <CelPhoneField inputHandle={handleCustomer} />
                        </div>

                        <div style={{ flexGrow: '2' }}>
                            <TextField 
                                type="email" 
                                id="txtEmail" 
                                name="email" 
                                label="E-mail" 
                                required 
                                fullWidth 
                                inputProps={{ maxLength: 60 }} 
                                onChange={handleCustomer} 
                            />
                        </div>
                    </div>
            }

            <Grid item xs={10}>
                <h4>Importante</h4>
                <p>Ao finalizar o pedido você visualizará o boleto diretamente em seu navegador.</p>
                <p>Você poderá efetuar o pagamento do boleto pela internet ou imprimí-lo e dirigir-se a uma agência bancária ou casa lotérica.</p>
                <p>O não pagamento do boleto resultará no cancelamento do pedido.</p>
            </Grid>

        </>
    );
};

export default DadosBoleto;
