import React from 'react';

import style from './style.module.css';
//import '../../style.css';

import { Paper, TextField, Card, CardContent, Button } from '@material-ui/core';


const initialAddress = {
    DESCRFIL: '',
    ENDER: '',
    ENDNR: '',
    BAIRR: '',
    MUNIC: '',
    UNFED: ''
}

const Success = ({ data }) => {
    const { pedido, pagamento, urlBoleto, linhaDigitavel } = data;

    const dadosEndereco = pedido["Retirada"] ? (pedido.Retirada || initialAddress) : (pedido.Entrega || initialAddress);

    const dataCriacao = new Date(pedido.DataCriacao).toLocaleDateString();
    const descrEndereco = pedido["Retirada"] ? 'Retirada' : 'Entrega';

    return (
        <div className={style.successContainer}>
            <div>
                { pagamento.FormaPagamento_Id === 3 ? <h1>Boleto gerado com Sucesso!</h1> : <h1>Pedido pago com Sucesso!</h1> }
            </div>
            <div>
                <Paper elevation={3} style={{ padding: '10px' }}>
                    <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
                        <div style={{ width: '100%' }}>
                            <h4>Dados do Pedido</h4>
                        </div>
                        <div className={style.growRowOnMobile}>
                            <TextField
                                label="Número do Pedido"
                                fullWidth
                                defaultValue={pedido.NumeroPedido}
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className={style.growRowOnMobile}>
                            <TextField
                                label="Data de Criação"
                                fullWidth
                                defaultValue={dataCriacao}
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div style={{ flexGrow: '1' }}>
                            <TextField
                                label="Status"
                                fullWidth
                                defaultValue={pedido.NomeStatusPedido}
                                InputProps={{ readOnly: true }}
                            />
                        </div>

                        <div style={{ width: '100%' }}>
                            <h4>Dados do Contato</h4>
                        </div>
                        <div style={{ flexGrow: '1' }}>
                            <TextField
                                label="Nome"
                                fullWidth
                                defaultValue={pedido.CTATO}
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div className={style.growRowOnMobile}>
                            <TextField
                                label="Celular"
                                fullWidth
                                defaultValue={`(${pedido.NRDDDFAX}) ${pedido.NRFAX}`}
                                InputProps={{ readOnly: true }}
                            />
                        </div>
                        <div style={{ width: '100%' }}>
                            <TextField
                                label="Email"
                                fullWidth
                                defaultValue={pedido.EmailWeb}
                                InputProps={{ readOnly: true }}
                            />
                        </div>

                        <div style={{ width: '100%' }}>
                            <h4>Dados da {descrEndereco}</h4>
                        </div>
                        <div className={style.growRowOnMobile}>
                            {
                                pedido["Retirada"] &&
                                    <TextField
                                        label="Loja para Retirada"
                                        fullWidth
                                        defaultValue={dadosEndereco.DESCRFIL}
                                        InputProps={{ readOnly: true }}
                                    />
                            }
                        </div>
                        <div style={{ flexGrow: '1' }}>
                            <TextField
                                label="Endereço"
                                fullWidth
                                defaultValue={`${dadosEndereco.ENDER} ${dadosEndereco.ENDNR} ${dadosEndereco.BAIRR} ${dadosEndereco.MUNIC} ${dadosEndereco.UNFED}`}
                                InputProps={{ readOnly: true }}
                            />
                        </div>

                        {
                            pagamento.FormaPagamento_Id === 3 &&
                                <>
                                    <div style={{ width: '100%' }}>
                                        <h4>Dados do Pagamento</h4>
                                    </div>
                                    <div className={style.divLinhaDigitavel}>
                                        <Card>
                                            <CardContent style={{ textAlign: 'center'}}>

                                                {
                                                    linhaDigitavel ?
                                                        <>
                                                            <h4>Código do Boleto</h4>
                                                            <h3>{ linhaDigitavel }</h3>
                                                        </>
                                                        :
                                                        <Button 
                                                            variant="contained" 
                                                            className='submitButton'
                                                            color="primary" 
                                                            target='_blank' 
                                                            href={urlBoleto}
                                                        >
                                                            Visualizar Boleto
                                                        </Button>
                                                }
                                            </CardContent>
                                        </Card>
                                    </div>

                                    <div className={style.divShowBoleto}>
                                        <iframe title="showBoleto" id="showBoleto" src={urlBoleto} style={{ width: '100%', height: '970px' }}></iframe>
                                    </div>
                                </>
                        }

                    </div>
                </Paper>
            </div>
        </div>

    )
};

export default React.memo(Success);
