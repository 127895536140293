import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

function getModalStyle() {
    return {
      top: '10%',
      left: '15%',
      overflow:'scroll',
      height:'80%',
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '70%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

const TermoUso = () => {

    const { filial_selecionada } = useSelector(state => state.constants);

    const razaoSocial = filial_selecionada.RazaoSocial;
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
  
return (

<div  style={modalStyle} className={classes.paper} >
<h3 style={{textAlign:'center'}}>TERMOS DE USO</h3>

<p>
Ao acessar esse site, você, pessoa física ou jurídica, estará manifestando ampla, irrestrita e irrevogável aceitação com todos os termos, cláusulas e condições do Contrato de Licença de Uso, razão pela qual a {razaoSocial}, recomenda que faça a leitura atenta desta página.
</p>
<p>
Ao navegar ou efetuar consulta pública a esse site ou quaisquer de suas páginas, o usuário terá aceitado explicitamente todos os Termos e Condições de Uso aqui estabelecidos. Caso não concorde com esses Termos e Condições de Uso, favor interromper imediatamente o acesso a esse site.
</p>
<br/>
<p>
{razaoSocial}
</p>
<p>
CONTRATO DE LICENÇA DE USO
</p>
<br/>
<p>
CLÁUSULAS E CONDIÇÕES GERAIS DO OBJETO:
</p>
<p>
Sujeito aos termos e condições deste contrato, a {razaoSocial} pelo presente, outorga ao Usuário uma licença pessoal, intransferível, não cedível e não exclusiva, para uso do site pelo tempo correspondente ao plano adquirido pelo Usuário.
</p><p>
A licença de uso objeto do presente contrato, permite que o Usuário, acesse pela internet, em qualquer tipo de computador ou dispositivo móvel, usando um browser compatível.
</p>
<br/>
<p>
DOS CUIDADOS COM O LOGIN E SENHA PARA ACESSO A ÁREA DO CLIENTE
</p>
<p>
O Usuário deverá manter seu login e senha confidenciais, os quais garantem acesso individual de acesso ao Site. Caso o Usuário tenha motivos para acreditar que sua senha foi de alguma forma descoberta por terceiros, deverá alterá-la imediatamente no Site. 
</p>
<p>
O Usuário concorda que é responsável por todos os dados fornecidos para o cadastramento, bem como por todos os atos praticados através de seu Login, devendo tomar todas as medidas necessárias para impedir a sua utilização inadequada, responsabilizando-se civil e criminalmente por estas informações e atos praticados. O Usuário concorda ainda que a {razaoSocial} não o protege contra o uso não autorizado de seu Login.
</p>
<p>
Se alguma informação prestada pelo Usuário não for verdadeira, exata, atual ou correta, e/ou a {razaoSocial} tiver motivos razoáveis para suspeitar que a informação não seja verdadeira, exata, atual ou correta, a {razaoSocial} terá o direito de suspender ou terminar o acesso do Usuário ao site.
</p>
<p>
Ao usar computadores a que terceiros tenham acesso, a {razaoSocial} recomenda ao Usuário, exercitar procedimentos de segurança para impedir que terceiros tenham acesso a seu login e senha e demais informações pessoais. 
</p>
<p>
A {razaoSocial} não se responsabilizará por nenhuma informação pessoal que o Usuário divulgar a terceiros, incluindo-se seu login e senha. A {razaoSocial} não será responsável face aos danos que o Usuário possa sofrer em razão de ter divulgado suas informações pessoais. 
</p>
<p>
Não é permitido ao Usuário escolher um Login que já esteja em uso, que possa ser ilegal, protegido por direitos de propriedade intelectual, que seja obsceno ou profano, que seja considerado inadequado pela {razaoSocial}, ou que viole leis da República Federativa do Brasil ou direitos de terceiros. A {razaoSocial} poderá cancelar qualquer Login que não guarde conformidade com o acima disposto, dando a opção ao Usuário de escolher um novo Login.
</p>
<br/>
<p>
DO USO DE DADOS DISPONIBILIZADOS PELO USUÁRIO: 
</p>
<p>
Tendo em vista que a {razaoSocial} terá acesso a informações pessoais do Usuário, bem como informações técnicas disponibilizadas pelo Usuário com o USO, a {razaoSocial} se compromete a proteger as informações disponibilizadas pelo Usuário, mantendo sigilo sobre as mesmas e resguardar os direitos individuais e privacidade do Usuário, exceto quando solicitadas em virtude de lei, ações judiciais ou procedimentos administrativos, quando solicitado por autoridades competentes, tais como, autoridade judicial ou autoridade governamental, independente de notificação, sempre respeitando os preceitos éticos profissionais.
</p>
<p>
A {razaoSocial} poderá extrair relatórios estatísticos baseados nas informações alimentadas pelo uso do site. Estas informações em hipótese alguma permitirão a identificação pessoal dos agentes envolvidos e de seus respectivos dados.
</p>
<br/>
<p>
DOS REQUISITOS ESSENCIAIS
</p>
<p>
O Usuário reconhece e concorda que a {razaoSocial} poderá, de tempos em tempos e a seu exclusivo critério, alterar as configurações técnicas necessárias, desde que mantenha o Usuário informado através do Site.
</p>
<p>
A {razaoSocial} ressalta que mesmo atingidos os requisitos de configuração para acesso ao site, não se pode garantir performances satisfatórias por motivos alheios à {razaoSocial}. 
</p>
<p>
DO ACESSO DO USUÁRIO À INTERNET: A {razaoSocial} não fornece acesso à Internet, sendo o Usuário responsável por todos os custos associados com sua conexão à Internet. O Usuário reconhece que a {razaoSocial} poderá adotar algumas políticas de segurança (por exemplo, a manutenção de “firewall”) que restrinjam o acesso ao site e deverá verificar se o seu acesso à Internet viabiliza o acesso ao site observando tais políticas de segurança, as quais poderão ser alteradas a exclusivo critério da {razaoSocial}.
</p>
<p>
DOS DIREITOS DA {razaoSocial} EM FACE DE CONDUTAS INACEITÁVEIS: Caso seja constatada qualquer tentativa de fraude no cadastro do Usuário junto ao Site, a {razaoSocial} bloqueará imediatamente o cadastro do Usuário, impedindo o acesso ao Site, sem direito a qualquer reembolso, e sem prejuízo de informar as autoridades competentes. 
</p>
<br/>
<p>
LIMITAÇÃO DE RESPONSABILIDADES E GARANTIA
</p>
<p>
Em nenhuma hipótese e em nenhuma circunstância, a somatória das responsabilidades da {razaoSocial} junto ao Usuário ou perante terceiros, por perdas e danos, lucros cessantes, prejuízos, perda ou extravio de dados, defeitos de computadores, equipamentos e periféricos ou indenizações, de qualquer espécie e sob quaisquer títulos, decorrentes direta ou indiretamente deste contrato, ultrapassará a quantia equivalente as taxas pagas pelo usuário/cliente à {razaoSocial}.
</p>
<p>
A {razaoSocial} não será responsável por quaisquer danos indiretos, acidentais, incidentais, especiais, punitivos, exemplares ou consequentes (inclusive, sem limitações, lucros cessantes, interrupção de negócios, perda de informações de negócios, ou qualquer outra perda pecuniária em conexão com os serviços ou qualquer produto eventualmente fornecido pela {razaoSocial}.
</p>
<p>
A {razaoSocial} reserva-se o direito de interromper o acesso ao Site periodicamente de forma regularmente programada com aviso prévio aos Usuários, a fim de executar qualquer espécie de manutenção necessária e/ou a seu exclusivo critério, com um compromisso de nível de serviço definido através de um SLA de 99,9% em relação ao hardware e de 95,8% em relação ao site, garantindo assim um serviço 24 X 7, com um excelente nível de estabilidade e segurança.
</p>
<p>
O acesso ao Site poderá ser interrompido por motivos de Força Maior, Caso Fortuito e qualquer outro motivo que não esteja sob controle da {razaoSocial}.
</p>
<p>
O Usuário concorda que o acesso ao Site poderá sofrer interrupções e que poderá haver momentos em que ele não poderá acessar ao site, eximindo expressamente a {razaoSocial} de qualquer responsabilidade. Em nenhuma hipótese e em nenhuma circunstância a {razaoSocial} poderá ser responsabilizada por danos e/ou obrigada ao pagamento de indenizações de qualquer espécie e sob quaisquer títulos, decorrentes da impossibilidade de acesso ou da interrupção do acesso ao Site ou, ainda, de sua performance inadequada. 
</p>
<br/>
<p>
DAS DISPOSIÇÕES GERAIS
</p>
<p>
A {razaoSocial} pode alterar este Contrato e a qualquer momento exclusivamente a seu critério, e a referida alteração passará a vigorar a partir de sua divulgação. Sempre que houver alterações no presente contrato, as mesmas serão disponibilizadas ao Usuário, no momento do login do Usuário ao Site. 
</p>
<p>
No caso de qualquer disposição do presente Contrato vier a ser considerada inválida, ilegal ou inaplicável, a validade, legalidade e aplicabilidade das disposições restantes não serão de modo algum afetadas ou prejudicadas. Nessa hipótese, a disposição considerada inválida, ilegal ou inaplicável deverá ser substituída por outra que conduza as partes ao mesmo resultado econômico e jurídico almejado.
</p>
<p>
As partes reconhecem e declaram que: (i) o não exercício ou o atraso em exercer, por qualquer das partes de qualquer direito que lhes seja assegurado por este Contrato de Licença ou pela lei, não constituirá novação ou renúncia desses direitos, nem prejudicará o eventual exercício dos mesmos no futuro; (ii) o exercício singular ou parcial desses direitos por qualquer das partes não impedirá posterior exercício dos mesmos, ou o exercício de qualquer outro direito; (iii) a renúncia, por qualquer das partes, de qualquer desses direitos não será válida, a menos que seja concedida por escrito; e (iv) a renúncia, por qualquer das partes, de qualquer desses direitos não será considerada como renúncia de qualquer outro direito a ela conferido através do presente Contrato de Licença.
</p>
<p>
DA LEI APLICÁVEL E DO FORO: Aplica-se ao presente Contrato de Licença de Uso a Lei Brasileira e fica eleito o Foro da Comarca de Jundiaí/SP, Brasil, com renúncia de qualquer outro por mais privilegiado que seja para dirimir qualquer litígio oriundo do presente.
</p>
<br/>
<p>
SOBRE OS TERMOS
</p>
<p>
Podemos modificar nossos termos de uso e política de privacidade ou quaisquer termos adicionais que sejam aplicáveis a um produto ou serviço para, por exemplo, refletir alterações da lei. Por este motivo, você deve consultar esta página regularmente.
</p>
</div>
)};

export default React.memo(TermoUso);