import React from 'react';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const CpfField = ({ cpf, inputHandle }) => {

    const cpfFormat = field => {
        let number = field.target.value;
        number = number.replace(/[^\d]/g, "");
      
        const newCpf = number.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

        field.target.value = newCpf;

        inputHandle(field);
    }

    const removeFormat = field => {
        let number = field.target.value;

        number = number.replace('-', '')
                        .split('.')
                        .join('');

        field.target.value = number;

        inputHandle(field);
    }


    return (
        <FormControl fullWidth >
            <InputLabel htmlFor="txtCpf">Cpf *</InputLabel>
            <Input
                type="text"
                name="cpf"
                value={cpf}
                onChange={inputHandle}
                onBlur={cpfFormat}
                onFocus={removeFormat}
                inputProps={{maxLength: 14}}
                required
                // inputComponent={TextMaskCustom}
            />
        </FormControl>
    );
}

export default CpfField;
