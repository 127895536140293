import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

const mostraInformacoes = data => {
    if(!data.AutorizacaoEspecial && !data.AutorizacaoFuncionalEspecial && !data.CoordenacaoVigilanciaMunicipalSaude && !data.NumeroProcon)
        return false;
    else
        return true ;
}

const InfoAdicional = ({ data }) => {
    if(!mostraInformacoes(data))
        return null;

    return (
        <>
        <h3 className="h2">Info. Adicional</h3>
        <List dense={true}>
            {
                data.AutorizacaoEspecial &&
                <>
                    <ListItem>
                        <ListItemText primary={`AUTORIZAÇÃO ESPECIAL - AE: ${data.AutorizacaoEspecial}`} />
                    </ListItem>
                    <Divider light />
                </>
            }
            {
                data.AutorizacaoFuncionalEspecial &&
                <>
                    <ListItem>
                        <ListItemText primary={`AUTORIZAÇÃO FUNCIONAL ESPECIAL - AFE: ${data.AutorizacaoFuncionalEspecial}`} />
                    </ListItem>
                    <Divider light />
                </>
            }
            {
                data.CoordenacaoVigilanciaMunicipalSaude &&
                <>
                    <ListItem>
                        <ListItemText primary={`COORDENAÇÃO VIGILÂNCIA MUNICIPAL DE SAÚDE - CVMS: ${data.CoordenacaoVigilanciaMunicipalSaude}`} />
                    </ListItem>
                    <Divider light />
                </>
            }
            {
                data.NumeroProcon &&
                <>
                    <ListItem>
                        <ListItemText primary={`PROCON: ${data.NumeroProcon}`} />
                    </ListItem>
                    <Divider light />
                </>
            }
        </List>
    </>
    )
};

export default React.memo(InfoAdicional);
