import createRequest from './api';

const addOrder = async ({ urlApi }, order) => {
	try{
		const api = createRequest(urlApi);
		const response = await api.post(
			'api/v3/mobypay/pedido', 
			order, 
			{ headers: { 'Content-Type': 'application/json' } }
		);
        
		if(response.status === 200)
			return response.data;

	}catch(error) {
		throw new Error(error);
	}
};

export {
	addOrder
};