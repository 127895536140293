// eslint-disable-next-line
const UrlQA = 'https://formulacertaqa.azurewebsites.net/';
// eslint-disable-next-line
const UrlDev = 'https://formulacertadev.azurewebsites.net/';
const UrlDev2 = 'https://formulacertaqav2.azurewebsites.net/';

module.exports = [
    { client: 'phusion', folder: 'fagron/phusion', tenantId: 'CFBDF8FB-0833-470C-82DC-77F0B7771809', urlApi: UrlDev },
    { client: 'phusion-staging', folder: 'fagron/phusion-staging', tenantId: 'C2D9536E-2A42-46F6-AF66-9412F769AA13', urlApi: UrlDev },
    { client: 'homologacao', folder: 'fagron/homologacao', tenantId: '8885DF59-C6A1-4A9F-8C70-92D4FE149566', urlApi: UrlQA },
    { client: 'irajar', folder: 'fagron/irajar', tenantId: 'FC5832CE-FA0F-472B-AA17-5E511AF832BF', urlApi: UrlQA },
    { client: 'formulacerta-qa', folder: 'fagron/formulacerta-qa', tenantId: '9c2d5e44-7402-42e1-bf50-8b7264b47c6a', urlApi: UrlQA },
    { client: 'formulacerta-onboarding', folder: 'fagron/formulacerta-onboarding', tenantId: 'c76b41fe-62ad-4b87-84c2-6de950f8272c', urlApi: UrlDev },
    { client: 'phusion-dev-03', folder: 'fagron/phusion-dev-03', tenantId: 'cfbdf8fb-0833-470c-82dc-77f0b7771809', urlApi: UrlDev },
    { client: 'phusion-onboarding', folder: 'fagron/phusion-onboarding', tenantId: '3563b8ab-2e4e-487c-bcfb-b6d2e12fd8cc', urlApi: UrlQA },
    { client: 'fenix-crm', folder: 'fagron/fenix-crm', tenantId: '8d93a512-3b8e-4263-8b5d-12f54c117287', urlApi: UrlDev },
]