import React from 'react';

import MaskedInput from 'react-text-mask';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            required
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

const CepField = ({ id, name, value, inputHandle, lostFocus }) => {
    return (
        <FormControl fullWidth >
            <InputLabel htmlFor={id}>Cep *</InputLabel>
            <Input
                type="text"
                id={id}
                name={name}
                value={value}
                onChange={inputHandle}
                onBlur={lostFocus}
                inputComponent={TextMaskCustom}
            />
        </FormControl>
    );
}

export default React.memo(CepField);
