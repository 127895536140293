import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const handleOptions = list => {
	const orderedList = list.sort((address1, address2) => {
		return address1.Endereco > address2.Endereco ? 1 : -1;
	});

	return orderedList.map(item => <option key={item.Id} value={item.Id}>{item.Endereco}, {item.Numero}, {item.Cidade}, {item.Estado} {item.Cep}</option>);
}

const EnderecoRetirada = ({ addressList, onChange }) => {
	return (
		<div style={{ width: '100%' }}>
			<FormControl fullWidth>
				<InputLabel htmlFor="selEnderecoRetirada">Endereço para retirada *</InputLabel>
				<Select 
					id="selEnderecoRetirada" 
					name="branch" 
					required 
					native 
					onChange={onChange} 
				>
					<option value=''></option>
					{ handleOptions(addressList) }
				</Select>
			</FormControl>
		</div>
	);
};

export default EnderecoRetirada;
