import convertFileToSend from '../../../util/convertFileToSend';
import { phoneFormat } from '../../../validates/phoneValidate';
import { addOrder } from '../../../main/services/sendOrder';
import validateFile from '../../../util/validateFileType';

const setNewOrderValues = (setOrder, order, { name, value }) => {
	if(name === 'delivery' && parseInt(value) === 0){

		const deliveryAddress = { 		
			description: '', 
			address: '', 
			number: '', 
			code: '', 
			district: '', 
			city: '', 
			state: ''
		};

		setOrder({ ...order, [name]: value, deliveryAddress });
		
	} else {
		setOrder({ ...order, [name]: value });
	}
}

const setNewAdressValues = (setOrder, order, { name, value }) => {
	const deliveryAddress = { ...order.deliveryAddress, [name]: value };
	setOrder({ ...order, deliveryAddress });
};

const setAdressByCep = (setOrder, order, data) => {
	const deliveryAddress = { ...order.deliveryAddress, 
		address: data.logradouro, 
		district: data.bairro, 
		city: data.localidade, 
		state: data.uf
	};
	setOrder({ ...order, deliveryAddress });
};

const setNewAttach = async (setOrder, order, { files }) => {
	if(files && files.length > 0) {
		const retorno = await validateFile(files[0]);		
		const baseFile = await convertFileToSend(retorno);
		const newAttach = {
			name: retorno.name,
			base: baseFile.split(',')[1]
		};
		const attachs = [ ...order.attachment ];
		attachs.push(newAttach);

		const newOrder = { ...order, attachment: attachs };
		setOrder(newOrder);
	}
};

const setDelAttach = (setOrder, order, evt) => {
	if(evt) {
		const attachs = order.attachment;
		const index = attachs.findIndex( item => item.name === evt.name);
		attachs.splice(index, 1);

		const newOrder = { ...order, attachment: attachs };

		setOrder(newOrder);
	}
};

const sendNewOrder = async (setOrder, clientRequest, tenantDestino, order, initialOrder) => {
	let attachs = [];
	if(order.attachment.length > 0) {
		order.attachment.forEach( item => {
			attachs.push({ NomeArquivo: item.name, ArquivoBase: item.base });
		});
	}

	const newOrder = {
		TenantId: clientRequest.tenantId,
		TenantDestino: tenantDestino,
		TituloReceita: 'Pedido MobyPay',
		Nome: order.name.toUpperCase(),
		Email: order.email,
		Telefone: phoneFormat(order.phone),
		ObservacoesAdicionais: order.comments,
		CanalId: 3,
		TipoRetirada: order.delivery,
		FilialId: order.branch,
		Anexos: attachs
	};

	if(parseInt(order.delivery) === 1){
		delete newOrder.FilialId;

		newOrder.Endereco = [{
			DESCREND: order.deliveryAddress.description,
			ENDER: order.deliveryAddress.address,
			ENDNR: order.deliveryAddress.number,
			NRCEP: order.deliveryAddress.code.replace('-',''),
			BAIRR: order.deliveryAddress.district,
			MUNIC: order.deliveryAddress.city,
			UNFED: order.deliveryAddress.state
		}];
	}

	const response = await addOrder(clientRequest, newOrder);

	setOrder(initialOrder);

	return response;
};

export {
	setNewOrderValues,
	setNewAdressValues,
	setAdressByCep,
	setNewAttach,
	setDelAttach,
	sendNewOrder
};