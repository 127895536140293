import arrayClient from '../customers/customersList';

export const convertUrlToClient = url => {
    const urlCli = url.indexOf('localhost') > -1 ? 'localhost' : url.split('//')[1].split('.')[0];
    const client = arrayClient.filter(item => item.client === urlCli);
    
    if(client.length === 0)
        return null;

    return client[0];
}