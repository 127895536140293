import React from 'react';

import styles from './formStyle.module.css';

import SearchByZipCode from '../../../main/services/searchByZipCode';

import CepField from './CepField';

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const listaUfs = ['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO'];

const FormEndereco = ({ onChange, inputAddressByZip, data }) => {
	const serachZipCode = async (e) => {
		try {
			const zipCode = e.target.value;

			if(zipCode){
				const response = await SearchByZipCode(zipCode);
				inputAddressByZip(response.data);
			}
            
		} catch (error) {
			console.error(error);
		}
	};


	return (
			<div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '20px' }}>
				<div>
					<CepField 
						name="code" 
						value={data.code} 
						inputHandle={onChange} 
						lostFocus={serachZipCode} 
					/>
				</div>
				<div>
					<div style={{ display: 'flex', flexFlow: 'row wrap' }}>
						<div style={{ flexGrow: '1' }}>
							<TextField 
								name="address" 
								label="Endereço" 
								required 
								fullWidth 
								inputProps={{ maxLength: 60 }}
								value={data.address} 
								onChange={onChange} 
							/>
						</div>
						<div className={styles.addressNumber}>
							<TextField 
								type="text" 
								name="number" 
								label="Numero" 
								required 
								fullWidth 
								inputProps={{ maxLength: 15 }}
								onChange={onChange} 
								value={data.number} 
							/>
						</div>
					</div>
				</div>
				<div>
					<TextField 
						name="description" 
						label="Complemento" 
						fullWidth 
						inputProps={{ maxLength: 30 }}
						onChange={onChange} 
						value={data.description} 
					/>
				</div>
				<div>
					<TextField 
						name="district" 
						label="Bairro" 
						required 
						fullWidth 
						inputProps={{ maxLength: 60 }}
						onChange={onChange} 
						value={data.district} 
					/>
				</div>
				<div>
					<div style={{ display: 'flex', flexFlow: 'row wrap' }}>
						<div style={{ flexGrow: '1' }}>
							<TextField 
								name="city" 
								label="Cidade" 
								required 
								fullWidth 
								onChange={onChange} 
								inputProps={{ maxLength: 20 }} 
								value={data.city} 
							/>
						</div>
						<div className={styles.addressNumber}>
							<FormControl fullWidth>
								<InputLabel htmlFor="cmbEstado">Estado *</InputLabel>
								<Select 
									id="cmbEstado" 
									name="state" 
									required 
									native 
									onChange={onChange} 
									value={data.state} 
								>
									<option value=''></option>
									{
										listaUfs.map(uf => <option key={uf} value={uf}> {uf} </option>)
									}
								</Select>
							</FormControl>
						</div>
					</div>
				</div>
			</div>
	);
};

export default React.memo(FormEndereco);
