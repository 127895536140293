import React from 'react';

import style from './style.module.css';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const DadosPedido = ({ data }) => {
    const label = data.TipoRetiradaPedido_Id === 0 ? 'retirada' : 'entrega';
    return (
        <Card className={style.orderCard}>
            <CardHeader title={`Dados da ${label}`} />
            <CardContent>
                <Typography color="textSecondary" gutterBottom>
                    <span className={style.subHeaderCard}>
                        Endereço: 
                        {
                            data.Endereco 
                                ? <span className={style.subHeaderInfo}>{data.Endereco}, {data.Numero} - {data.Bairro}, {data.Cidade} - {data.Uf}, {data.Cep} </span> 
                                : ' Não informado'
                        }
                    </span>
                </Typography>
            </CardContent>
        </Card>
    )
}


export default React.memo(DadosPedido);
