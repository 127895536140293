const getCardCode = (bandeira, listaBandeiras) => {
    const config = listaBandeiras.filter( item => item.Bandeira.toLowerCase()===bandeira );
    return config && config.length > 0 ? config[0].Codigo : 0;
}

const obterPagseguroCardToken = (sessionId, creditCard) => new Promise((resolve, reject) => {
    window.PagSeguroDirectPayment.setSessionId(sessionId);
    window.PagSeguroDirectPayment.createCardToken({
        cardNumber: creditCard.cardNumber,
        brand: creditCard.cardType,
        cvv: creditCard.cvc,
        expirationMonth: creditCard.expireDate.split('/')[0],
        expirationYear: creditCard.expireDate.split('/')[1],
        success: function (response) {
            resolve(response.card.token);
        },
        error: function (response) {
            console.error(response);
            reject(response);
        }
    });
});

const obterPagseguroSenderHash = () => window.PagSeguroDirectPayment.getSenderHash();

const getToday = () => {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export default {
    getCardCode,
    obterPagseguroCardToken,
    obterPagseguroSenderHash,
    getToday
}