import React from 'react';

import Grid from '@material-ui/core/Grid';
import icons from '../../assets/icons';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

const fillChips = (chips = [], onDelete) => {
	const list = Array.from(chips);
	return list.map( (item, index) => <Chip key={index} label={item.name} onDelete={() => onDelete(item)} style={{ marginLeft: '10px', maxWidth: '200px' }} /> );
};

const Attachment = ({ attachList, onChange, onDelete, styleButton }) => {
	return (
		<Grid item xs={12} className={styleButton}>
			<div style={{ marginBottom: '10px' }}>
				{ fillChips(attachList, onDelete) }
			</div>
			<input
				accept="*/*"
				style={{ display: 'none' }}
				id="anexarReceita"
				type="file"
				value=''
				onChange={onChange}
			/>
			<label htmlFor="anexarReceita">
				<Button variant="contained" component="span" startIcon={<icons.CloudUploadIcon />}>
                    Anexar receitas
				</Button>
			</label> 
		</Grid>
	);
};

export default React.memo(Attachment);
