import React from 'react';

import MaskedInput from 'react-text-mask';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            required
            mask={[/\d/, /\d/, '/', /\d/, /\d/,'/', /\d/, /\d/,/\d/,/\d/]}
            placeholderChar={'\u2000'}
        />
    );
}

const birthDay = ({ value, inputHandle, onFocus }) => {
    return (
        <FormControl fullWidth >
            <InputLabel htmlFor='txtBirthdayDate'>Data de Nascimento *</InputLabel>
            <Input
                type="text"
                id='txtBirthdayDate'
                required
                name='birthDay'
                value={value}
                onChange={inputHandle}
                onFocus={onFocus}
                inputComponent={TextMaskCustom}
            />

        </FormControl>
    );
}

export default React.memo(birthDay);