import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import SendOrder from '../components/SendOrder';
import OnLinePay from '../components/OnlinePay';

const Routes = () => (
	<Router>
		<Switch>
			<Route exact path="/" component={SendOrder} />
			<Route exact path="/:registro" component={OnLinePay} />
			<Route path="/orcamento-online-detalhes/:registro" component={OnLinePay} />

			<Redirect from="*" to="/" />
		</Switch>
	</Router>
);

export default Routes;