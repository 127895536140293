import React, { useState, useEffect } from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

const Parcelas = ({ total, liquido, config, dataSelected, handleInstalments }) => {
    const [ avaliableInstalments, setAvaliableInstalments ] = useState([]);

    useEffect(() => {
        if(config){
            const instalmentsToValue = config.valorMinimoParcela.filter(conf => conf.valor <= liquido);

            const instalments = instalmentsToValue.map(parc => {
                parc.TaxaJuros = (parc.TaxaJuros !== null ? parseFloat(parc.TaxaJuros) : 0);
                parc.qtdParcelas = parseInt(parc.qtdParcelas);

                const juros = (parc.TaxaJuros && parc.TaxaJuros > 0 ? (parc.TaxaJuros / 100) : 0);
                const valorJuros = (liquido / parc.qtdParcelas) * juros;
                const valorParcela = (liquido / parc.qtdParcelas) + valorJuros;
                const valorTotal = valorParcela * parc.qtdParcelas;
                return { 
                    qtdParcelas: parc.qtdParcelas, 
                    juros, 
                    label: (!parc.TaxaJuros || parc.TaxaJuros===0 ? 'sem juros' : 'com juros'), 
                    valorMdr: parc.Mdr,
                    valorFee: parc.Fee,
                    valorJuros: valorJuros.toFixed(2), 
                    valorParcela: valorParcela.toFixed(2), 
                    valorTotal: valorTotal.toFixed(2) 
                };
            });

            setAvaliableInstalments(instalments);
            handleInstalments(instalments[0]);
        }
    }, [config]);

    const handleChangeInstalments = e => {
        const selected = avaliableInstalments.find(item => item.qtdParcelas === parseInt(e.target.value));
        selected && handleInstalments(selected);
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="lblParcelas">Número de Parcelas</InputLabel>
            <Select 
                labelId="lblParcelas" 
                id="selParcelas" 
                native 
                value={dataSelected} 
                onChange={handleChangeInstalments}
            >
                { 
                    avaliableInstalments.map((parc, index ) => 
                        <option key={index} value={parc.qtdParcelas}>
                            {parc.qtdParcelas} x {parc.label} de R$ {parc.valorParcela}. Total R$ {parc.valorTotal}
                        </option>
                    )
                }
            </Select>
        </FormControl>
    );
}

export default React.memo(Parcelas);
