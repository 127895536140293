import emailValidate from './emailValidate';
import { phoneValidate, phoneFormat } from './phoneValidate';

let hasErrors = false;

const addErrorClass = e => {
	e.parentElement.classList.add('Mui-error');
	e.parentElement.parentElement.firstChild.classList.add('Mui-error');

	hasErrors = true;
};

const validators = () => {
	const requiredFields = document.querySelectorAll('[required]');
	hasErrors = false;

	requiredFields.forEach((e) => {
		if (e.value === '' || e.value === 0){
			addErrorClass(e); 
		}
		if (e.type === 'email' && !emailValidate(e.value)) {
			addErrorClass(e);
		}
		if (e.type === 'tel' && e.name === 'phone') { 
			const phone = phoneFormat(e.value);
			if(!phoneValidate(phone)) {
				addErrorClass(e);
			}
		}
		if (e.type === 'select-one' && parseInt(e.value) === -1) {
			addErrorClass(e);
		}
	});

	return hasErrors;
};

export default validators;
