import React from 'react';

import styles from './style.module.css';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormEndereco from '../Common/Form/FormEndereco';

import TextField from '@material-ui/core/TextField';

import CelPhoneField from '../Common/Form/CelPhoneField';
import CpfField from '../Common/Form/CpfField';


const DadosCadastrais = ({ addressData, handleCustomer, inputHandleAddress, inputAddressByZip }) => (
    <Card style={{ marginBottom: '20px' }}>
        <CardContent>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                    <b>Dados Cadastrais</b>
                </div>
                <div>
                    <TextField 
                        id="txtNome" 
                        name="name" 
                        label="Nome" 
                        required 
                        fullWidth 
                        onChange={handleCustomer}  
                        inputProps={{ maxLength: 30 }} 
                    />
                </div>
                <div>
                    <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
                        <div style={{ flexGrow: '1' }}>
                            <CpfField inputHandle={handleCustomer} />
                        </div>
                        <div className={styles.phoneNumber}>
                            <CelPhoneField inputHandle={handleCustomer} />
                        </div>
                    </div>                        
                </div>
                <div>
                    <TextField 
                        type="email" 
                        id="txtEmail" 
                        name="email" 
                        label="E-mail" 
                        required 
                        fullWidth 
                        inputProps={{ maxLength: 60 }} 
                        onChange={handleCustomer} 
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <b>Endereço</b>
                </div>
                <div>
                    <FormEndereco 
                        data={addressData}
                        align='left' 
                        descricao='Endereço' 
                        onChange={inputHandleAddress} 
                        inputAddressByZip={inputAddressByZip} 
                    />
                </div>
            </div>
        </CardContent>
    </Card>
);

export default React.memo(DadosCadastrais);
