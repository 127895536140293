import createRequest from './api';

let dataCustomerInfo = {
	provedorPagto: '',
	formaPagamento: ''
};

const getPaymentData = async ({ tenantId, urlApi }, filialId) => {
	try {

		const api = createRequest(urlApi);
		const response = await api.get(`api/v3/mobypay/cliente/${tenantId}/dados-pagto?filialId=${filialId}`);

		if(response.status === 200){
			if(!response.data.Errors)
				dataCustomerInfo = response.data;
		}

		return dataCustomerInfo;
	} catch (e) {
		console.error(e);
	}
};


const getOrderDetails = async ({tenantId, urlApi }, registroPedido) => {
	try {
		
		const api = createRequest(urlApi);
		const response = await api.get(`api/v3/mobypay/cliente/${tenantId}/pedido/${registroPedido}/detalhes`);

		if(response.status === 200){
			if(!response.data.Errors)
				dataCustomerInfo = response.data;
		}

		return dataCustomerInfo;
	} catch (e) {
		console.error(e);
	}
};

const getPagseguroSession = async ({ tenantId, urlApi }) => {
	try {
		const api = createRequest(urlApi);
		const response = await api.get(`api/v2/pagseguro/Sessions?tenantId=${tenantId}`);

		if(response.status === 200){
			return response.data;
		}

		return null;
	} catch (e) {
		console.error(e);
	}
};

export {
	getPaymentData,
	getOrderDetails,
	getPagseguroSession
};