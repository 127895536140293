import createApi from '../services/api';

const INITIAL_STATE = {
	tenantDestino: '', 
	filiais: [],
	loading: false,
	clientRequest: {},
	filial_selecionada: {}
};

export const Types = {
	TOKEN: 'SET_TOKEN',
	INFO_PAGTO: 'SET_INFO_PAGTO',
	FILIAIS: 'GET_FILIAIS',
	TENANT_DESTINO: 'TENANT_DESTINO',
	LOADING_DATA: 'LOADING_DATA',
	CLIENT_REQUEST: 'CLIENT_REQUEST',
	FILIAL_SELECIONADA: "FILIAL_SELECIONADA"
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {		
		case Types.TOKEN:
			return { ...state, token: action.payload };
			
		case Types.INFO_PAGTO:
			return { ...state, provedorPagto: action.payload.provedor, formaPagamento: action.payload.forma };
		
		case Types.FILIAIS:
			return { ...state, filiais: action.payload };
		
		case Types.TENANT_DESTINO:
			return { ...state, tenantDestino: action.payload };

		case Types.LOADING_DATA:
			return { ...state, loading: action.payload };
		
		case Types.CLIENT_REQUEST:
			return { ...state, clientRequest: action.payload };

		case Types.FILIAL_SELECIONADA:
			return { ...state, filial_selecionada: action.payload };

		default:
			return state;
	}
};

/* ACTION CREATORS */
const getTokenApi = async urlApi => {
	try {
		const api = createApi(urlApi);

		const data = `grant_type=password&username=${INITIAL_STATE.userName}&password=Senha_10`;
		const response = await api.post('token', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

		if(response.status === 200){
			return response.data.access_token;
		}

		return null;

	} catch (error) {
		console.error(error);
	}
};
const getToken = () => async dispatch => {
	try {
		let returnToken = '';

		if(INITIAL_STATE.token){
			returnToken = INITIAL_STATE.token;

		} else {
			returnToken = await getTokenApi();
		}

		dispatch({ type: Types.TOKEN, payload: returnToken });

	} catch (error) {
		console.error(error);
	}
};


const setInfoPagto = (provedor, forma) => dispatch => dispatch({ type: Types.INFO_PAGTO, payload: { provedor, forma }});

const getFiliais = ({ urlApi, tenantId }) => async dispatch => {
	try {
		const api = createApi(urlApi);
		const response = await api.get(`api/v3/mobypay/cliente/${tenantId}/filiais`);

		if(response.status === 200){
			dispatch({ type: Types.FILIAIS, payload: response.data.FILIAIS });
			dispatch({ type: Types.TENANT_DESTINO, payload: response.data.FILIAIS[0].TenantDestino });
		}

	} catch (error) {
		dispatch({ type: Types.FILIAIS, payload: [] });
		console.error(error);
	}
};

const setLoading = loading => dispatch => dispatch({ type: Types.LOADING_DATA, payload: loading });

const setClientRequest = data => dispatch => dispatch({ type: Types.CLIENT_REQUEST, payload: data });

const setFilialSelecionada = filial => dispatch => dispatch({type: Types.FILIAL_SELECIONADA, payload: filial});

export const Creators = {
	setLoading,
	getToken,
	setInfoPagto,
	getFiliais,
	setClientRequest,
	setFilialSelecionada
};

/*
	token expirado em 17/01
	crsS58S49_qAqI5N2mFOnLNG0rxjV8nN8-I_OFoar-m_-vjfG7MgAw_1Q26lgpmJ6G1ZPy_E3PHntOIlbast9I6bHZBn1fbjQt6ChCPdrjksv4oYsuZdzZldY5BTJm9-bqH0Zc6Uo3tOPUd-7oCF08qVgDLe8f-Y2DOcxy-NPkEixKg2b_XKvw6vBusGai5hrxsSAX1o7d0v0IE-BILtYEMTGAJyCPpFlnh_S68Uj0xWg4u_y0S4wB2jZwm4lLgu3xWXCRmEnNU2bE8wpMqls2263q5QdLKTABC8z02cJiHSLS-KOePkh6Ff_8atAq4D0drhhnptE6YeVydluUO1JgqkZohbL6fZVQm5nuUepZ2Vb4bMNajz4qZNmf4X1CcvZ0sMNLFutCO_ZywfGIk5hXoAPhktxCfdqtg9zqoRav4
*/