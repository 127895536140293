import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SendIcon from '@material-ui/icons/Send';
import NoteIcon from '@material-ui/icons/Note';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import HospitalIcon from '@material-ui/icons/LocalHospital';
import EmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/LocalPhone';
import WhatsIcon from '@material-ui/icons/WhatsApp';
import HomeIcon from '@material-ui/icons/Home';
import PixIcon from '@material-ui/icons/QueuePlayNextOutlined';

export default {
	CloudUploadIcon,
	SendIcon,
	NoteIcon,
	CreditCardIcon,
	HospitalIcon,
	EmailIcon,
	PhoneIcon,
	WhatsIcon,
	HomeIcon,
	PixIcon
};
