import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import icons from '../../../assets/icons';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import InfoAdicional from './InfoAdicional';

import { Creators as ConstCreators } from '../../../main/ducks/constants';

const initialBranch = {
	Id: 0,
	CodigoFilial: '',
	DescricaoFilial: '',
	Endereco: '',
	Numero: '',
	Bairro: '',
	Cep: '',
	Cidade: '',
	Estado: '',
	DddTelefone: '',
	Telefone: '',
	DddCelular: '',
	Celular: '',
	Email: '',
	RazaoSocial: '',
	CNPJ: '',
	Responsavel: '',
	Funcionamento: ''
}

const Footer = () => {
	const [ branchList, setBranchList ] = useState([]);
	const [ selectedBranch, setBranch ]	= useState(initialBranch);

	const dispatch = useDispatch();

	const { filiais } = useSelector(state => state.constants);

	useEffect(() => {
		setBranchList(filiais);
	}, [filiais]);

	useEffect(() => {
		console.log(selectedBranch);
		dispatch(ConstCreators.setFilialSelecionada(selectedBranch));
	}, [selectedBranch]);

	const hanldeBranchChange = e => changeSelectField(e.target.value);

	const changeSelectField = value => {
		if(value && branchList) {
			const branch = branchList.filter(item => item.Id === parseInt(value));

			// console.log(branch);

			if(branch)
				setBranch(branch[0]);
		}
	}

	if(selectedBranch.Id === 0 && branchList.length > 0) changeSelectField(branchList[0].Id);

	return (
		<div className="rodape">
			<div className="filialRodape">
				<FormControl fullWidth>
					<Select 
						id="selectBranchs" 
						native 
						value={ selectedBranch.Id===0 ? '' : selectedBranch.Id }
						onChange={hanldeBranchChange} 
					>
						<option value=''></option>
						{
							branchList.map(item => <option key={item.Id} value={item.Id} >{ item.DescricaoFilial }</option>)
						}
					</Select>
					<FormHelperText>Selecione a unidade para mostrar as informações de contato</FormHelperText>
				</FormControl>
			</div>
			
			<div className="infoRodape">
				<div>
					<h3 className="h2">Contato</h3>
					<List dense={true}>
						{
							selectedBranch.Email &&
							<>
								<ListItem>
									<ListItemIcon>
										<icons.EmailIcon />
									</ListItemIcon>
									<ListItemText primary={selectedBranch.Email} />
								</ListItem>
								<Divider light />
							</>
						}
						{
							selectedBranch.Celular &&
							<>
								<ListItem>
									<ListItemIcon>
										<icons.WhatsIcon />
									</ListItemIcon>
									<ListItemText primary={`(${selectedBranch.DddCelular}) ${selectedBranch.Celular}`} />
								</ListItem>
								<Divider light />
							</>
						}
						{
							selectedBranch.Telefone &&
							<>
								<ListItem>
									<ListItemIcon>
										<icons.PhoneIcon />
									</ListItemIcon>
									<ListItemText primary={`(${selectedBranch.DddTelefone}) ${selectedBranch.Telefone}`} />
								</ListItem>
								<Divider light />
							</>
						}
						{
							selectedBranch.Endereco &&
							<>
								<ListItem>
									<ListItemIcon>
										<icons.HomeIcon />
									</ListItemIcon>
									<ListItemText primary={`${selectedBranch.Endereco} nº${selectedBranch.Numero}, ${selectedBranch.Bairro}, ${selectedBranch.Cidade} / ${selectedBranch.Estado}`} />
								</ListItem>
							</>
						}
					</List>
				</div>
				<div>
					<h3 className="h2">Informações</h3>
					<List dense={true}>
						{
							selectedBranch.CNPJ &&
							<>
								<ListItem>
									<ListItemText primary={`CNPJ: ${selectedBranch.CNPJ}`} />
								</ListItem>
								<Divider light />
							</>
						}
						{
							selectedBranch.RazaoSocial &&
							<>
								<ListItem>
									<ListItemText primary={`RAZÃO SOCIAL: ${selectedBranch.RazaoSocial}`} />
								</ListItem>
								<Divider light />
							</>
						}
						{
							selectedBranch.Responsavel &&
							<>
								<ListItem>
									<ListItemText primary={`FARM. RESPONSÁVEL: ${selectedBranch.Responsavel}`} />
								</ListItem>
								<Divider light />
							</>
						}
						{
							selectedBranch.Funcionamento &&
							<>
								<ListItem>
									<ListItemText primary={`HORÁRIO DE FUNCIONAMENTO: ${selectedBranch.Funcionamento}`} />
								</ListItem>
							</>
						}
					</List>
				</div>
				<div>
				{
					selectedBranch &&
						<InfoAdicional data={selectedBranch} />
				}
			</div>
			</div>
		</div>
	);
};

export default React.memo(Footer);
