import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import style from './sendOrder.module.css';

import { Creators as ConstCreators } from '../../main/ducks/constants';

import ToastMessage from '../Common/msgToast';

import Info from './Info';
import DadosSolicitante from './DadosSolicitante';
import FormaEntrega from './FormaEntrega';
import Attachment from './Anexos';
import Success from './Success';

import validators from '../../validates/requireValidate';

import TextField from '@material-ui/core/TextField';
import { FormControlLabel } from '@material-ui/core';
import { Button, ButtonBase, Checkbox, Link, Modal } from '@material-ui/core';

import icons from '../../assets/icons';

import { setNewOrderValues, setNewAdressValues, setAdressByCep, setNewAttach, setDelAttach, sendNewOrder } from './functions';

import TermoUso from '../Common/TermoUso';

const initialOrder = { 
	name: '', 
	phone: '', 
	email: '', 
	comments: '', 
	delivery: -1, 
	branch: -1, 
	attachment: [],
	deliveryAddress: {
		description: '', 
		address: '', 
		number: '', 
		code: '', 
		district: '', 
		city: '', 
		state: ''
	} 
};


const SendOrder = props => {
	const [ addressList, setAddressList ] = useState([]);
	const [ order, setOrder ] = useState(initialOrder);
	const [ response, setResponse ] = useState({});

	const dispatch = useDispatch();

	const { clientRequest, filiais, tenantDestino } = useSelector(state => state.constants);

	useEffect(() => {
		if(window.location.hash.indexOf('#') > -1) {
			const splitUrl = window.location.hash.split('/');
			const registro = splitUrl[splitUrl.length-1];

			props.history.push(`/${registro}`);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setAddressList(filiais);
	}, [filiais]);

	const changeInputValue = e => setNewOrderValues(setOrder, order, e.target);

	const changeAdressInputValue = e => setNewAdressValues(setOrder, order, e.target);

	const changeAdressInputbyCep = (data) => setAdressByCep(setOrder, order, data);

	const addAttachment = async e => setNewAttach(setOrder, order, e.target);

	const delAttachment = e => setDelAttach(setOrder, order, e);

	const [isDisabled, setIsDisabled] = useState(true);
    const [checked, setChecked] = useState(false);
    
	const [ open, setOpen ] = useState(false);

    const handleOpen = () => {
        setOpen(true);
      };

    const handleClose = () => {
        setOpen(false);
      };


	const sendBudget = async (e) => {
		e.preventDefault();

		if(!validators()){
			try {
				dispatch(ConstCreators.setLoading(true));

				const response = await sendNewOrder(setOrder, clientRequest, tenantDestino, order, initialOrder);
				// console.log(response);
				setResponse(response);

				window.scrollTo(0, 0);	

				ToastMessage('Solicitação enviada com Sucesso!', true);

			}catch(e) {
				ToastMessage('Erro na solicitação do orçamento!', false);
				console.error(e);
			}finally {
				dispatch(ConstCreators.setLoading(false));
			}

		}
	};

	if(response && response.Id)
		return <Success data={response} />

	const canBeSubmitted = () => {
		return checked ? setIsDisabled(true) : setIsDisabled(false);
		};

	const onCheckboxClick = () => {
		setChecked(!checked);
		return canBeSubmitted();
	};
		
	return (
		<div style={{ display: 'flex', flexFlow: 'row wrap', width: '80%', padding: '5px' }}>
			<div>
				<Info />
			</div>
			<div className={style.sendOrderForm}>
				<form onSubmit={sendBudget}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div>
							<DadosSolicitante 
								inputHandle={changeInputValue} 
								name={order.name}
								phone={order.phone}
								email={order.email}
							/>
						</div>
						<div>
							<TextField 
								id="txtObservacao" 
								name="comments" 
								label="Observações" 
								multiline 
								rows="4" 
								value={order.comments} 
								fullWidth 
								inputProps={{ maxLength: 200 }}
								onChange={changeInputValue} 
							/>
						</div>
						<div>
							<FormaEntrega
								handleInput={changeInputValue}
								handleAddressInput={changeAdressInputValue}
								inputHandleJS={changeAdressInputbyCep}
								addressList={addressList}
								delivery={order.delivery}
								deliveryAddress={order.deliveryAddress}
							/>
						</div>
						<div>
							<Attachment 
								styleButton={style.buttonAttach}
								attachList={order.attachment}
								onChange={addAttachment} 
								onDelete={delAttachment}
							/>
						</div>
						<div style={{ alignSelf: 'flex-end', padding: '10px' }}>
							<Modal
								open={open}
								onClose={handleClose}
								aria-labelledby="simple-modal-title"
								aria-describedby="simple-modal-description"
							>
								<TermoUso></TermoUso>
							</Modal>
                                    
							<Link onClick={handleOpen} style={{paddingRight:"10px"}}>Ler os termos</Link>
							
							<FormControlLabel control={<Checkbox name="liAceito" onClick={onCheckboxClick} />} label="Li e aceito." />
                                    

							<ButtonBase type='submit' disabled={isDisabled}>
								<Button 
									variant="contained" 
									color="primary" 
									className='submitButton'
									component='span'
									startIcon={<icons.SendIcon />} 
									onClick={sendBudget}
								>
									Enviar
								</Button>
							</ButtonBase>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SendOrder;
