import React from 'react';

import style from './sendOrder.module.css';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';


const Success = ({ data }) => {
    const dadosEndereco = data["Retirada"] ? data.Retirada : data.Entrega
    const dataCriacao = new Date(data.DataCriacao).toLocaleDateString();
    const descrEndereco = data["Retirada"] ? 'Retirada' : 'Entrega';

    return (
        <Grid container direction="column" justify="center" alignItems="center" className={style.gridContent}>
            <Grid item xs={10}>
                <h1>Solicitação enviada com Sucesso!</h1>
            </Grid>
            <Grid item xs={10}>
                <Paper elevation={3}>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3} style={{ padding: '10px' }}>
                        <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                            Dados do Pedido
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="txtNumeroPedido"
                                label="Número do Pedido"
                                fullWidth
                                defaultValue={data.NumeroPedido}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="txtDataCriacao"
                                label="Data de Criação"
                                fullWidth
                                defaultValue={dataCriacao}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <TextField
                                id="txtStatus"
                                label="Status"
                                fullWidth
                                defaultValue={data.NomeStatusPedido}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                    </Grid>
                    
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3} style={{ padding: '10px' }}>
                        <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                            Dados do Contato
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="txtNome"
                                label="Nome"
                                fullWidth
                                defaultValue={data.CTATO}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="txtTelefone"
                                label="Celular"
                                fullWidth
                                defaultValue={`(${data.NRDDDFAX}) ${data.NRFAX}`}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <TextField
                                id="txtEmail"
                                label="Email"
                                fullWidth
                                defaultValue={data.EmailWeb}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                    </Grid>
                    
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3} style={{ padding: '10px' }}>
                        <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                            Dados da {descrEndereco}
                        </Grid>
                        {
                            data["Retirada"] &&
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="txtFilialRetirada"
                                        label="Loja para Retirada"
                                        fullWidth
                                        defaultValue={dadosEndereco.DESCRFIL}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Grid>
                        }
                        <Grid item xs={12}>
                            <TextField
                                id="txtEndereco"
                                label="Endereço"
                                fullWidth
                                defaultValue={`${dadosEndereco.ENDER} ${dadosEndereco.ENDNR} ${dadosEndereco.BAIRR} ${dadosEndereco.MUNIC} ${dadosEndereco.UNFED}`}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
};

export default Success;
