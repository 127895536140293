import React, { useState, useEffect  } from 'react';

import style from './style.module.css';
//import '../../style.css';

import Grid from '@material-ui/core/Grid';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { Radio, RadioGroup } from '@material-ui/core';
import { FormControlLabel, FormControl, FormLabel } from '@material-ui/core';
import { ButtonBase, Button } from '@material-ui/core';
import { Select, InputLabel, Checkbox, Link, Modal } from '@material-ui/core';

import DadosCadastrais from './DadosCadastrais';
import DadosCartao from './FormasPagto/DadosCartao';
import DadosBoleto from './FormasPagto/DadosBoleto';
import DadosPix from './FormasPagto/DadosPix';
import FormEndereco from '../Common/Form/FormEndereco';

import TermoUso from '../Common/TermoUso';

const DadosPagamento = ({ 
    order, 
    configPagto,
    addressFunctions,
    cardFunctions,
    handleCustomer,
    formaPagto, 
    inputHandleFormaPagto,
    handdleSubmit }) => {
    
    const [isDisabled, setIsDisabled] = useState(true);
    const [checked, setChecked] = useState(false);
    const [ showNewAddress, setShowNewAddress ] = useState(false);
    const [ addressError, setAddressError ] = useState(false);
    const [ open, setOpen ] = useState(false);

    const handleOpen = () => {
        setOpen(true);
      };

    const handleClose = () => {
        setOpen(false);
      };

      useEffect(() => {             
        setShowNewAddress(false);
        if(order.EnderecosCliente != null && order.EnderecosCliente.length){
            setShowNewAddress(false);
            addressFunctions.handleExistAddress(order.EnderecosCliente[0].Id);
        }     
        
      },[]);    
    
    const showFormaPagto = () => {
        if(configPagto){
            
            switch(formaPagto) {
                case 'cartao':
                    return <DadosCartao 
                                cardData={cardFunctions.cardData} 
                                valorTotal={order.ValorTotal} 
                                valorLiquido={order.ValorLiquido} 
                                configPagto={configPagto.Cartoes} 
                                selectedInstalment={cardFunctions.selectedInstallment}
                                handleInstalments={cardFunctions.handleInstalments}
                                inputHandleCreditCard={cardFunctions.inputHandleCreditCard}
                            />;
                case 'boleto':
                    return <DadosBoleto 
                                order={order} 
                                handleCustomer={handleCustomer} 
                            />;
                    
                 case 'pix':
                    return <DadosPix 
                                order={order} 
                                handleCustomer={handleCustomer} 
                            />;
                
                default:
                    return null;
            }
        }
        return null;
    };

    const handleNewAddress = e => {
        setAddressError(false);
        setShowNewAddress(parseInt(e.target.value)===0);
        addressFunctions.handleExistAddress(e.target.value);
    };

    const validarClienteVinculado = () => {
        const createSelectItems = () => 
            order.EnderecosCliente && 
                order.EnderecosCliente.map( item => {
                    return <option key={item.Id} value={item.Id}>{item.ENDER} nº{item.ENDNR}, {item.BAIRR} - {item.MUNIC}/{item.UNFED}</option>
                })        
        
        if(order.Cliente_Id && formaPagto && formaPagto !== 'pix') {
            const label = formaPagto === 'boleto' ? 
                'Endereço que aparecerá no boleto' : 
                'Endereço do titular do cartão';
            return (
                <Grid item xs={12}>
                    <FormControl fullWidth style={{ marginBottom: '20px' }} error={addressError}>
                        <InputLabel id="lblEndereco">{label}</InputLabel>
                        <Select
                            labelId="lblEndereco"
                            id="selEndereco"
                            native 
                            required
                            value={addressFunctions.address.id}
                            onChange={handleNewAddress}
                        >
                            <option value=''></option>
                            { 
                                createSelectItems() 
                            }
                            <option value={0}>- Novo -</option>
                        </Select>
                    </FormControl>
                </Grid>
            );
        }
        
    };  

    const submitForm = e => {
        e.preventDefault();

        if(!showNewAddress && !addressFunctions.address.id && order.Cliente_Id){
            if(formaPagto === 'pix'){
                handdleSubmit();
            }
            setAddressError(!showNewAddress && !addressFunctions.address.id);
        }else{
            handdleSubmit();
        }
    }

    const canBeSubmitted = () => {
        return checked ? setIsDisabled(true) : setIsDisabled(false);
      };

    const onCheckboxClick = () => {
        setChecked(!checked);
        return canBeSubmitted();
      };

    return (
        <Card className={style.orderCard}>
            <CardHeader title="Pagamento" />
            <CardContent>
                {
                    !order.Cliente_Id &&
                        <DadosCadastrais 
                            handleCustomer={handleCustomer} 
                            addressData={addressFunctions.address} 
                            inputHandleAddress={addressFunctions.handleAddress} 
                            inputAddressByZip={addressFunctions.handleAddressByZip}
                        />
                }

                <Card>
                    <CardContent>
                        <form name="formPagto" onSubmit={submitForm}>

                            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Forma de Pagamento *</FormLabel>
                                        <RadioGroup row aria-label="formaPagto" value={formaPagto} name="formaPagto" required onChange={inputHandleFormaPagto}>
                                            {   configPagto?.Cartao?.Ativo === false ? <></> :
                                                configPagto?.Cartao?.Ativo !== false  &&
                                                <FormControlLabel value="cartao" control={<Radio required />} label="Cartão de crédito" />
                                            }
                                            {
                                                configPagto?.Boletos?.Ativo &&
                                                    <FormControlLabel value="boleto" control={<Radio required />} label="Boleto" />
                                                    
                                            }
                                            {
                                                configPagto?.Pix?.Ativo &&
                                                    <FormControlLabel value="pix" control={<Radio required />} label="Pix" />
                                                    
                                            }
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                { validarClienteVinculado() }

                                {
                                    showNewAddress &&
                                        <FormEndereco 
                                            data={addressFunctions.address} 
                                            onChange={addressFunctions.handleAddress} 
                                            inputAddressByZip={addressFunctions.handleAddressByZip} 
                                        />
                                }

                                { showFormaPagto() }
                                
                                <Grid item xs={12} style={{ textAlign: 'right' }}>
                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="simple-modal-title"
                                        aria-describedby="simple-modal-description"
                                    >
                                        <TermoUso></TermoUso>
                                    </Modal>
                                    
                                    <Link onClick={handleOpen} style={{paddingRight:"10px"}}>Ler os termos</Link>
                                    
                                    <FormControlLabel control={<Checkbox name="liAceito" onClick={onCheckboxClick} />} label="Li e aceito." />
                                    
                                    <ButtonBase 
                                        type='submit' 
                                        component='button'
                                        disabled={isDisabled}
                                    >
                                        <Button 
                                            variant="contained" 
                                            className='submitButton'
                                            color="primary" 
                                            component='span'
                                        >
                                            Finalizar
                                        </Button>
                                    </ButtonBase>
                                </Grid>

                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </CardContent>
        </Card>
    );
}

export default DadosPagamento;
