import React from 'react';

import icons from '../../assets/icons';

import Hidden from '@material-ui/core/Hidden';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';

const Info = () => (
	<div style={{ display: 'flex', flexFlow: 'column', marginRight: '20px' }}>
		<div>
			<h2>Orçamento simples e fácil da sua receita!</h2>
		</div>
		<div>
			<Hidden smDown>
				<p className="h3">VEJA COMO É FÁCIL:</p>
				<List>
					<ListItem>
						<ListItemIcon>
							<icons.NoteIcon />
						</ListItemIcon>
						<ListItemText primary="Anexe suas receitas, preencha seus dados e clique em enviar." />
					</ListItem>
					<Divider light />
					<ListItem>
						<ListItemIcon>
							<icons.CreditCardIcon />
						</ListItemIcon>
						<ListItemText primary="Receba seu orçamento e link para pagamento." />
					</ListItem>
					<Divider light />
					<ListItem>
						<ListItemIcon>
							<icons.HospitalIcon />
						</ListItemIcon>
						<ListItemText primary="Pronto! Receba em casa ou retire seu pedido na loja." />
					</ListItem>
				</List>
			</Hidden>
		</div>
	</div>
);

export default React.memo(Info);
