import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import logo from '../../../assets/logo.png';

const Header = () => {
	const { clientRequest } = useSelector(state => state.constants);
	const refLogo = useRef('');

	useEffect(() => {
		if(clientRequest.folder !== 'localhost') {
			const url = new URL(window.location.href);
			refLogo.current = clientRequest.folder ? `${url.origin}/customers/${clientRequest.folder}/logo.png` : '';
		} else {
			refLogo.current = logo;
		}
	}, [clientRequest]);

	return (
		<div className='headerContainer'>
			<a href='/'>
				<img src={refLogo.current} alt="Logo da farmácia" />
			</a>
		</div>
	)
};

export default React.memo(Header);
