import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';
import packageJson from '../../../package.json';

import { Creators as ConstCreators } from '../../main/ducks/constants';

import Grid from '@material-ui/core/Grid';

import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Loader from '../Common/Loading';

import Routes from '../../main/Routes';

import { convertUrlToClient } from '../../util/convertUrlToClient';

console.log(`################ MobyPay version ${packageJson.version} #############################`);

const App = () => {
	const { loading } = useSelector(state => state.constants);
	const refClientRequest = useRef({});
	const refClientStyle = useRef('');

	const dispatch = useDispatch();

	// eslint-disable-next-line
	useEffect(() => {
		const url = new URL(window.location.href);
		// const url = new URL('https://desenv.mobypay.com.br/4F91977D-EFB1-4D35-8310-3987114A2C54');

		refClientRequest.current = convertUrlToClient(url.origin);
		refClientStyle.current = `${url.origin}/customers/${refClientRequest.current.folder}/style.css`;

		dispatch(ConstCreators.setClientRequest(refClientRequest.current));
	}, []);

	useEffect(() => {
		if(refClientStyle.current) {
			if(refClientRequest.current.client !== 'localhost'){
				const linkElement = document.createElement('link');
				
				linkElement.rel = 'stylesheet';
				linkElement.href = refClientStyle.current;
	
				document.head.appendChild(linkElement);
			} else {
				require('../../style.css');
			}
		}
	}, []);

	useEffect(() => {
		dispatch(ConstCreators.getFiliais(refClientRequest.current));
	}, [dispatch]);


	return (
		<BrowserRouter>
			<div className="App">
				<Grid container spacing={3} direction="row" justify="center" alignItems="center" className="content">
					<Header />
					<Loader open={loading} />
					<Routes />
					<Footer />
				</Grid>
			</div>
		</BrowserRouter>
	);
};

export default App;
