import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const ItensWeb = ({ listaItens, formatarValor, collapseFunctions, valorAdicional, valorTotalItens, valorTotalDescontosItens }) => {
    
    const { handleCollapse, collapsed } = collapseFunctions;

    const preencheTitulo = (item, titulo) => {
        if(item.TipoItemPedido_Id === 1) {
            return <Button color="primary" onClick={() => handleCollapse(item.ItemId)} style={{ textAlign: 'left' }}>
                { titulo }
            </Button>
        } else {
            return titulo;
        }
    }

    return (
        <TableContainer> 
            <Table aria-label="spanning table">
                <TableHead>
                    <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell align="right">Qtd.</TableCell>
                        <TableCell align="right">Valor Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        listaItens &&
                        listaItens.map(item => {
                            const titulo = item.TipoItemPedido_Id === 1 ? item.Descricao.split(' | ')[0] : item.Descricao;
                            const composicao = item.TipoItemPedido_Id === 1 ? item.Descricao.split(' | ')[1].split(';') : '';

                            return (
                                <React.Fragment key={item.ItemId}>
                                    <TableRow>
                                        <TableCell>
                                            { preencheTitulo(item, titulo) }
                                        </TableCell>
                                        <TableCell align="right">{ item.Quantidade }</TableCell>
                                        <TableCell align="right">{ formatarValor(item.ValorLiquido) }
                                        </TableCell>
                                    </TableRow>
                                    {
                                        item.TipoItemPedido_Id === 1 &&
                                            <TableRow style={{ backgroundColor: '#FAFAFA' }}>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                                                    <Collapse in={collapsed(item.ItemId)} timeout="auto" unmountOnExit>
                                                        { composicao.map(comp => <Box margin={1} key={comp} style={{ borderBottom: '1px solid #D8D8D8', fontSize: '12px' }}>{ comp }</Box>) }
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                    }
                                </React.Fragment>
                            )
                        })
                    }

                    <TableRow>
                        <TableCell colSpan={2}>Subtotal</TableCell>
                        <TableCell align="right">{ formatarValor(valorTotalItens) }</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>Frete</TableCell>
                        <TableCell align="right">{ formatarValor(valorAdicional) }</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>Desconto</TableCell>
                        <TableCell align="right">{ formatarValor(valorTotalDescontosItens) }</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>Total</TableCell>
                        <TableCell align="right">{ formatarValor(valorTotalItens-valorTotalDescontosItens+valorAdicional) }</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ItensWeb;
