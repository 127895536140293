import React from 'react';

import style from './style.module.css';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { ButtonBase, Button } from '@material-ui/core';
import { getOrderDetails } from '../../main/services/onlinePay';
import { useParams } from 'react-router-dom';
import { helpers } from '../../util/helpers';
import ToastMessage from '../Common/msgToast';

const DadosPedido = ({ data, onChangePix, client }) => {
    const dataCriacao = new Date(data.DataCriacao).toLocaleDateString();
    const history = useHistory();
    const [seconds, setSeconds] = useState(0);
    const [pedidoPago, setPedidoPago] = useState(0);

    
    const { registro } = useParams();
    let [secondsPaymentRequest, setSecondsPaymentRequest] = useState(15);
    
    let dadosPix;

    if (data?.dadosPix) {
        dadosPix = JSON.parse(data.dadosPix);
    }

    const [showPix, setShowPix] = useState(false);
    const [widthCounter, setWidthCounter] = useState(100);
    const [time, setTime] = useState('');

    useEffect(() => {
        let myInterval = setInterval(() => {
            
            setPedidoPago(data.StatusPedidoPago_Id)
            if (seconds === 0 && dadosPix?.TempoExpiracaoSegundos) {
                setSeconds(dadosPix.TempoExpiracaoSegundos)
            }

            setSecondsPaymentRequest(secondsPaymentRequest - 1);
            
            if (secondsPaymentRequest === 0 && data.StatusPedidoPago_Id !== 1 && dadosPix) {
                verificarStatusPagamento();
            }

            const width = (seconds * 100) / 3600;
            let time = helpers.convertHMS(seconds);
            if (seconds > 0) {
                setSeconds(seconds - 1);
                setWidthCounter(width);
                setTime(time);
            }
            if (seconds === 0) {
                clearInterval(myInterval)
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    function verificarStatusPagamento() {
        client.tenantId &&
            client.urlApi &&
            getOrderDetails(client, registro)
                .then(resp => {

                    if (resp.StatusPedidoPago_Id === 1) {
                        data = resp;
                        ToastMessage('Pagamento efetuado com sucesso!', true);
                        setTimeout(function () {
                            setPedidoPago(1)
                            window.location.reload(false);
                        }, 5000);
                    } else {
                        setSecondsPaymentRequest(15);
                    }
                })
                .catch(e => {
                    console.error(e);
                })
    }

    return (
        <Card className={style.orderCard}>
            <CardHeader title="Informações do Pedido" />
            <CardContent>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Typography color="textSecondary" gutterBottom>
                            <span className={style.subHeaderCard}>
                                Mensagem: <span className={style.subHeaderInfo}>{data.TITULO}</span>
                            </span>
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                            <span className={style.subHeaderCard}>
                                Observações: <span className={style.subHeaderInfo}>{data.OBSERVACAO}</span>
                            </span>
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                            <span className={style.subHeaderCard}>
                                Data da solicitação: <span className={style.subHeaderInfo}>{dataCriacao}</span>
                            </span>
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                            <span className={style.subHeaderCard}>
                                Status: <span className={style.subHeaderInfo}>{data.StatusPedidoDescricao}</span>
                            </span>
                        </Typography>
                        {
                            data.NomeCliente &&
                            <>
                                <Typography color="textSecondary" gutterBottom>
                                    <span className={style.subHeaderCard}>
                                        Nome: <span className={style.subHeaderInfo}>{data.NomeCliente}</span>
                                    </span>
                                </Typography>
                                <Typography color="textSecondary" gutterBottom>
                                    <span className={style.subHeaderCard}>
                                        E-mail: <span className={style.subHeaderInfo}>{data.EmailCliente}</span>
                                    </span>
                                </Typography>
                            </>
                        }
                    </div>
                    {
                        data.FormaPagamento_Id === 5 &&

                        <div style={{ borderLeft: '1px solid lightgrey', paddingLeft: '60px', width: '50%' }}>
                            <Typography color="textSecondary" gutterBottom>
                                <span className={style.subHeaderCard}>
                                    Forma de Pagamento: <span style={{ color: '#000', fontSize: '16px' }}>PIX</span>
                                </span>
                            </Typography>

                            {
                                seconds > 0 && pedidoPago !== 1 &&

                                <div>
                                    <Typography component={'span'} color="textSecondary" gutterBottom style={{ marginTop: '25px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '25px' }}>
                                            <span className={style.subHeaderCard}>
                                                Tempo restante para realizar o pagamento:
                                            </span>
                                            <span style={{
                                                color: '#D38C29',
                                                fontSize: ' 18px',
                                                fontWeight: '800'
                                            }}>
                                                {time}
                                            </span>
                                        </div>
                                        <div className="counter">
                                            <div style={{ width: widthCounter + '%' }} className="inner-counter"></div>
                                        </div>
                                    </Typography>

                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: "center",
                                            alignItems: 'center',
                                            marginTop: '20px'
                                        }}


                                    >
                                        <ButtonBase
                                            type='submit'
                                            component='button'

                                        >
                                            <Button
                                                variant="contained"
                                                component='span'

                                                style={{
                                                    border: '1px solid #D38C29',
                                                    backgroundColor: "white",
                                                    color: '#D38C29'
                                                }}

                                                onClick={() => onChangePix(seconds)}
                                            >
                                                VER QR CODE

                                            </Button>
                                        </ButtonBase>
                                    </div>
                                </div>
                            }


                        </div>
                    }
                    {
                        data.FormaPagamento_Id === 3 && data.urlBoleto && data.StatusPedidoPago_Id !== 1 &&

                        <div style={{ borderLeft: '1px solid lightgrey', paddingLeft: '20px', width: '50%' }}>
                            <Typography color="textSecondary" gutterBottom>
                                <span className={style.subHeaderCard}>
                                    Forma de Pagamento: <span style={{ color: '#000', fontSize: '16px' }}>Boleto</span>
                                </span>
                            </Typography>

                            {

                                data.StatusPedidoPago_Id !== 1 &&

                                <div style={{
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: 'center',
                                    marginTop: '20px',
                                    height: '100%'
                                }}>
                                    <ButtonBase
                                        type='submit'
                                        component='button'
                                        href={data.urlBoleto}
                                        target='_blank'
                                    >
                                        <Button
                                            variant="contained"
                                            component='span'

                                            style={{
                                                border: '1px solid #D38C29',
                                                backgroundColor: "white",
                                                color: '#D38C29'
                                            }}

                                        >
                                            VER BOLETO

                                        </Button>
                                    </ButtonBase>
                                </div>
                            }



                        </div>
                    }
                </div>

            </CardContent>
        </Card>
    )
}


export default React.memo(DadosPedido);
