import imageCompression from 'browser-image-compression';

const imageTypes = ['png', 'jpg', 'jpeg', 'gif', 'bmp'];

var options = {
	maxSizeMB: 1,
	maxWidthOrHeight: 1920,
	useWebWorker: true
};

const validateFile = async file => {
	const type = file.type.split('/')[1];
	const tipoValido = imageTypes.indexOf(type);
    
	if(tipoValido > -1){
		// console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

		const compressedFile = await imageCompression(file, options);
        
		// console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);

		return compressedFile;
	}

	return file;
};

export default validateFile;
