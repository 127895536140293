import React from 'react';

import CelPhoneField from '../Common/Form/CelPhoneField';

import TextField from '@material-ui/core/TextField';

const DadosSolicitante = ({ inputHandle, name, phone, email }) => {
	return (
		<>
			<div>
				<TextField 
					id="txtNome" 
					name="name" 
					label="Nome" 
					value={name} 
					required 
					inputProps={{ maxLength: 30 }} 
					fullWidth 
					onChange={inputHandle} 
				/>
			</div>
			<div>
				<CelPhoneField 
					phone={phone}
					inputHandle={inputHandle}
				/>
			</div>
			<div>
				<TextField 
					type="email" 
					id="txtEmail" 
					name="email" 
					label="E-mail" 
					value={email} 
					required 
					inputProps={{ maxLength: 60 }} 
					fullWidth 
					onChange={inputHandle} 
				/>
			</div>
		</>
	);

};

export default React.memo(DadosSolicitante);
