import React from 'react';

import './styles.css';
import { ReactComponent as PixIcon } from '../../../../assets/pix.svg';
import Grid from '@material-ui/core/Grid';

const DadosPix = ({ order }) => {

    return (
        <>
            {
                order &&
                <div style={{width: '100%', marginLeft: '10px'}}>
                    <Grid item xs={10}>
                        <h4><PixIcon style={{marginBottom: '-6px'}} /> Pagar com PIX</h4>
                        <div style={{marginLeft: '30px'}}>
                            <p>Ao concluir o código PIX será gerado para realizar o pagamento.</p>
                            <p>O pagamento pode ser feito por QR Code ou PIX Copia e Cola.</p>
                            <p>O não pagamento dentro de 60 minutos resultará no cancelamento do pedido.</p>
                        </div>
                    </Grid>
                </div>
            }

           
        </>
    );
};

export default DadosPix;
