import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import EnderecoRetirada from './EnderecoRetirada';
import FormEndereco from '../Common/Form/FormEndereco';

const FormaEntrega = ({ handleInput, handleAddressInput, inputHandleJS, addressList, delivery, deliveryAddress }) => {
	const selectEndereco = () => {
		switch (parseInt(delivery)) {
			case 0:
				return <EnderecoRetirada 
					addressList={addressList} 
					onChange={handleInput} 
				/>;
	
			case 1:
				return <FormEndereco 
					onChange={handleAddressInput} 
					inputAddressByZip={inputHandleJS} 
					descricao='Informe o endereço que deseja receber seu pedido' 
					data={deliveryAddress} 
				/>;
	
			default: 
				return null;
		}
	};

	return (
		<>
			<div>
				<FormControl fullWidth>
					<InputLabel htmlFor="selFormaEntrega">Selecione a forma de entrega *</InputLabel>
					<Select 
						id="selFormaEntrega" 
						name="delivery" 
						required 
						native 
						onChange={handleInput} 
						value={delivery}
					>
						<option value=''></option>
						<option value={0}>Retirar na loja</option>
						<option value={1}>Receber em casa</option>
					</Select>
				</FormControl>
			</div>
            <div style={{ display: 'flex', flexDirection:"row", alignItems:"center" }} >
				{ selectEndereco() }
			</div>
		</>
	);
};

export default React.memo(FormaEntrega);
