import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';


import style from "./styles.css";
import globalStyle from '../../../../../components/OnlinePay/style.module.css';
import { ReactComponent as PixIcon } from "../../../../../assets/pix.svg";

import Grid from "@material-ui/core/Grid";
import { Card, CardHeader, CardContent, Button, ButtonBase } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ToastMessage from '../../../../Common/msgToast';
import { getOrderDetails } from '../../../../../main/services/onlinePay';
import { useParams } from 'react-router-dom';
import { helpers } from '../../../../../util/helpers';


const QrCode = ({ data, order, client }) => {

  const [seconds, setSeconds ] =  useState(0);
  const [widthCounter , setWidthCounter] = useState(100);
  const [time , setTime] = useState('');
  const [secondsPaymentRequest, setSecondsPaymentRequest ] =  useState(15);
  const { registro } = useParams();
  const [pedidoPago, setPedidoPago ] = useState(0);
  const { clientRequest } = useSelector(state => state.constants);
  let dadosPix;

  if (client === undefined) {
    client = clientRequest;
  } 

  useEffect(()=>{
    let myInterval = setInterval(() => {
            
            setPedidoPago(data.StatusPedidoPago_Id)
            if (data?.TempoExpiracaoSegundos) {
              setSeconds(data.TempoExpiracaoSegundos)
            } else {
              setSeconds(3600);
            }
            setSecondsPaymentRequest(secondsPaymentRequest - 1);
            
            if(secondsPaymentRequest === 0 && data.StatusPedidoPago_Id !== 1) {
                verificarStatusPagamento();                    
            }
            
            const width = (seconds * 100) / 3600;
            let time = helpers.convertHMS(seconds);
            if (seconds > 0) {
                setSeconds(seconds - 1);
                setWidthCounter(width);
                
                setTime(time);
            }
            if (seconds === 0) {
              clearInterval(myInterval)
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
          };
    });

    function verificarStatusPagamento() {
      client.tenantId && 
      client.urlApi &&
      getOrderDetails(client, registro)
              .then(resp => {
                  
                  if (resp.StatusPedidoPago_Id === 1) {
                      data = resp;
                      ToastMessage('Pagamento efetuado com sucesso!', true);
                      setTimeout(function() {
                          setPedidoPago(1)
                          window.location.reload(false);
                     }, 5000);     
                  } else {
                      setSecondsPaymentRequest(15);
                  }
              })
              .catch(e => {
                  console.error(e);
              })
    }
 
  const date = new Date(data.pedido.DataCriacao.slice(-1) !== 'Z' ? data.pedido.DataCriacao + 'Z' : data.pedido.DataCriacao);

  const dateFix = dataAtualFormatada(date);
  const hours = (date.getHours()<10?'0':'') + date.getHours() + ":" + (date.getMinutes()<10?'0':'') + date.getMinutes();
  const endereco =`${order.Endereco}, ${order.Numero}- ${order.Bairro}, ${order.Cidade}- ${order.Uf}, ${order.Cep}`;
  const loja = data?.pedido?.Retirada?.DESCRFIL;

  function handleCopy() {
    navigator.clipboard.writeText(data.linhaDigitavel)
    ToastMessage('Código copiado com Sucesso!', true);
  }

  function dataAtualFormatada(date){
    var data = new Date(date),
        dia  = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0'+dia : dia,
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0'+mes : mes,
        anoF = data.getFullYear();
    return diaF+"/"+mesF+"/"+anoF;
  }

  return (
    <>
      {order && (
        <>
          <div className={globalStyle.successContainer}>
            <h2 className={globalStyle.titulo} style={{width: "100%"}}>Dados do Pedido #{order.NumeroPedido}</h2>
          </div>
          <Card className={globalStyle.successContainer}>
            
            
            <CardContent>
              <h4>
                Dados do Pedido
              </h4>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={9} md={9}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={4} md={4}>
                      <TextField
                        type="text"
                        name="orderNumber"
                        label="Número do pedido"
                        required
                        fullWidth
                        disabled
                        defaultValue={data.pedido.NumeroPedido || order.NumeroPedido}
                        inputProps={{
                          style: { color: '#000' },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <TextField
                        type="text"
                        name="orderDate"
                        label="Data do pedido"
                        required
                        fullWidth
                        disabled
                        defaultValue={dateFix}
                        inputProps={{
                          style: { color: '#000' },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <TextField
                        type="text"
                        name="hour"
                        label="Hora"
                        required
                        fullWidth
                        disabled
                        defaultValue={hours}
                        inputProps={{
                          style: { color: '#000' },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={6} md={6}>
                      <TextField
                        type="text"
                        name="orderValue"
                        label="Valor do pedido"
                        required
                        fullWidth
                        disabled
                        defaultValue={"R$" + order.ValorLiquido.toLocaleString('pt-br', {minimumFractionDigits: 2})}
                        inputProps={{
                          style: { color: '#000' },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        type="text"
                        name="status"
                        label="Status"
                        required
                        fullWidth
                        disabled
                        defaultValue={data.pedido.NomeStatusPedido}
                        inputProps={{
                          style: { color: '#000' },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                                
                  <Grid item xs={12} md={12}>
                  <h4>   
                    Dados da                 
                    {
                      data.TipoRetiradaPedido_Id === 0 ? ' retirada' : ' entrega'
                    }
                  </h4>
                  <Grid container direction="row" spacing={3}>
                    { 
                      data.TipoRetiradaPedido_Id === 0 && 
                      <Grid item xs={3} md={3}>
                        <TextField
                          type="text"
                          name="orderNumber"
                          label="Loja para Retirada"
                          required
                          fullWidth
                          disabled
                          defaultValue={loja}
                          inputProps={{
                            style: { color: '#000' },
                          }}
                        />
                    </Grid>
                    }
                    <Grid item xs={9} md={9}>
                      <TextField
                        type="text"
                        name="orderDate"
                        label="Endereço"
                        required
                        fullWidth
                        disabled
                        defaultValue={endereco}
                        inputProps={{
                          style: { color: '#000' },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
        
                <Grid item xs={12} md={12}>
                    <h4 className="flex-align">
                      <PixIcon /> <span style={{marginLeft: "10px"}}>Pagar com PIX</span>
                    </h4>
                  <Grid container direction="row" spacing={3} style={{marginLeft: '0px'}}>
                    <div className="step-item">
                      <div className='circulo'>1</div>
                      <p className="text-pix">Abra o App do seu Banco ou Instituição Financeira e entre no ambiente PIX.</p>
                    </div>
                    <div className="step-item">
                      <div className='circulo'>2</div>
                      <p className="text-pix"> Escaneie o QR Code ou use o código PIX Copia e Cola.</p>
                    </div>
                    <div className="step-item">
                      <div className='circulo'>3</div>
                      <p className="text-pix">Confirme as informações e finalize o pagamento em até 60 minutos.</p>
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={4} md={4}>
                      <img width="400" height="400" className="qrCode" src={data.qrCode}></img>
                    </Grid>
                    <Grid item xs={7} md={7}  style={{marginLeft: "73px", padding: "0"}}>
                      <p className="pix-title">PIX Copia e Cola</p>
                      <textarea cols="40" rows="2" className="pix-full" defaultValue={data.linhaDigitavel + '...'}>                      
                      </textarea>
                      <div className="button-copy-pix-code">
                        <ButtonBase 
                          type='submit' 
                          component='button'
                      >
                          <Button 
                              variant="contained" 
                              component='span'
                              onClick={handleCopy}

                              style={{
                                border: '1px solid #D38C29',
                                backgroundColor: "white",                                  
                                color: '#D38C29'
                            }}
                          >
                              COPIAR CÓDIGO PIX
                            </Button>
                        </ButtonBase>
                        
                      </div>
                      <div>
                        <div className="pix-counter">
                          <p>Tempo restante para realizar o pagamento.</p>
                          <p className="timer">{time}</p>
                        </div>
                        <div className="counter">
                          <div style={{width: widthCounter + '%'}} className="inner-counter"></div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            
          </Card>
        </>
      )}
    </>
  );
};

export default QrCode;
