export default (gateway, ambienteProducao) => {
	switch (gateway) {
	case 'P': 
		mountPagSeguro(ambienteProducao);
		break;

	case 'B':
		mountBrasPag();
		break;

	case 'M':
		const fingerPrintId = makeFingerPrintId();
		mountMobyPay(fingerPrintId);
		return fingerPrintId;

	default: 
		break;
	}
};

const mountPagSeguro = (ambienteProducao) => {
	console.log('PagSeguro');

	const script = ambienteProducao==='True' ? 
					'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js' 
					:
					'https://stc.sandbox.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js';

	var pagSeguroScripts = document.createElement('script');
	pagSeguroScripts.src = script;

	return document.body.appendChild(pagSeguroScripts);
};

const makeFingerPrintId = () => {
	const hoje = new Date();
	return `${hoje.getDate()}${hoje.getMonth()}${hoje.getFullYear()}${hoje.getHours()}${hoje.getMinutes()}${hoje.getSeconds()}${hoje.getMilliseconds()}`;
};
const mountMobyPay = (fingerPrintId) => {
	console.log('MobyPay');

	var mobypayScripts = document.createElement('script');
	// mobypayScripts.src = `https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id=FAGRON${fingerPrintId}`;
	mobypayScripts.src = `https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id=braspag${fingerPrintId}`;
    
	return document.head.appendChild(mobypayScripts);
};

const mountBrasPag = () => {
	console.log('BrasPag');
};