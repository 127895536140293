import createRequest from './api';

const sendPayment = async (urlApi, data) => {
	try{
		const api = createRequest(urlApi);

		const response = await api.post(
			`api/v3/mobypay/pedido/${data.registroPedido}/pagamento`,
			data, 
			{ headers: { 'Content-Type': 'application/json' } }
		);
        
		if(response.status === 200){
			return response.data;
		}
	}catch(error) {
		console.error(error);

		if(error.response && error.response.data && error.response.data.ExceptionMessage) {
			let errorMessage = JSON.parse(error.response.data.ExceptionMessage);
			console.error(errorMessage.errors);
			
		}else if(error.response && error.response.data && error.response.data.Message) {
			console.error(error.response.data.Message);

			const cardError = error.response.data.Message.split('\r\n');
			error.message = (cardError.length > 0 ? cardError[0] : error.response.data.Message);
		}

		throw new Error(error);
	}
};

export {
	sendPayment
};