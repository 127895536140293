import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import ExpireDate from './ExpireDate';
import BirthdayDate from './BirthdayDate';
import Parcelas from './Parcelas';
import CpfField from '../../../Common/Form/CpfField';
import CelPhoneField from '../../../Common/Form/CelPhoneField';

const DadosCartao = ({ cardData, valorTotal, valorLiquido, configPagto, selectedInstalment, handleInstalments, inputHandleCreditCard }) => {
    const [ focus, setFocus ] = useState('');

    let handleFocus = e => setFocus(e.target.name);

    const handleFocusExpire = handleFocus;
    const handleFocusBirthday = handleFocus;

    const configParaBandeira = bandeira => {
        if(bandeira){
            const config = configPagto.filter( card => card.Bandeira.toLowerCase() === bandeira.toLowerCase() );
            return config ? config[0] : null;
        }
    };
    
    const config = configParaBandeira(cardData.cardType);

    return (
        <Card>
            <CardContent>
                <Grid container direction="row" justify='flex-start' alignItems='center' spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Cards
                            number={cardData.cardNumber}
                            name={cardData.cardOwner}
                            expiry={cardData.expireDate}
                            cvc={cardData.cvc}
                            focused={focus}
                            locale={ {valid: 'Validade'}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="row" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    type="text" 
                                    name="cardNumber" 
                                    label="Número do cartão" 
                                    value={cardData.cardNumber}
                                    required fullWidth 
                                    inputProps={{ maxLength: 18 }}
                                    onChange={inputHandleCreditCard}
                                    onFocus={e => setFocus(e.target.name)} 
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <ExpireDate 
                                    value={cardData.expireDate} 
                                    inputHandle={inputHandleCreditCard} 
                                    onFocus={handleFocusExpire} 
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField type="text" 
                                    id="txtCodigoSeguranca" 
                                    name="cvc" 
                                    label="CVV" 
                                    value={cardData.cvc}
                                    inputProps={{ maxLength: 4 }} 
                                    required 
                                    fullWidth 
                                    onChange={inputHandleCreditCard}
                                    onFocus={e => setFocus(e.target.name)} 
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField type="text" 
                                    name="cardOwner" 
                                    label="Titular do cartão" 
                                    value={cardData.cardOwner}
                                    required fullWidth 
                                    inputProps={{ maxLength: 30 }}
                                    onChange={inputHandleCreditCard}
                                    onFocus={e => setFocus(e.target.name)}  
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CpfField 
                                    cpf={cardData.cpf}
                                    inputHandle={inputHandleCreditCard}
                                    onFocus={e => setFocus(e.target.name)} 
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CelPhoneField 
                                    required 
                                    phone={cardData.phone}
                                    inputHandle={inputHandleCreditCard}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <BirthdayDate 
                                    value={cardData.birthDay} 
                                    inputHandle={inputHandleCreditCard} 
                                    onFocus={handleFocusBirthday} 
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField 
                                    type="email" 
                                    name="email" 
                                    label="E-mail" 
                                    value={cardData.email}
                                    required 
                                    fullWidth 
                                    inputProps={{ maxLength: 60 }}
                                    onChange={inputHandleCreditCard}
                                />
                            </Grid>
                            {
                                config &&
                                    <Grid item xs={12}>
                                        <Parcelas 
                                            total={valorTotal} 
                                            liquido={valorLiquido} 
                                            config={config} 
                                            dataSelected={selectedInstalment} 
                                            handleInstalments={handleInstalments} 
                                        />
                                    </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default DadosCartao;
