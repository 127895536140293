exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".sendOrder_buttonAttach__O9Qxb { margin-top: 15px !important; }\n.sendOrder_attachItem__CE_j9 {\n    margin: 10px 10px 10px 0;\n}\n\n.sendOrder_sendButton__5x149 { \n    text-align: right; \n    margin-top: 40px !important;\n    margin-bottom: 10px !important;\n}\n\n.sendOrder_gridContent__ypikt { \n    margin-bottom: 20px !important;\n}\n.sendOrder_gridContent__ypikt h1{ margin-bottom: 30px !important; }\n\n.sendOrder_paperContent__3Fswd {\n    width:50% !important;\n}\n\n.sendOrder_sendOrderForm__30sM- {\n    flex-grow: 1;\n}\n\n@media (min-width: 799px) {\n    .sendOrder_sendOrderForm__30sM- {\n        min-width: 400px;\n    }\n}\n\n\n@media (max-width: 959px) \n{\n    .sendOrder_gridContent__ypikt {\n        width:100% !important;\n    }\n    .sendOrder_gridContent__ypikt h1{ font-size: 20px !important; }\n    .sendOrder_paperContent__3Fswd {\n        width:100% !important;\n    }\n\n}\n\n", ""]);

// Exports
exports.locals = {
	"buttonAttach": "sendOrder_buttonAttach__O9Qxb",
	"attachItem": "sendOrder_attachItem__CE_j9",
	"sendButton": "sendOrder_sendButton__5x149",
	"gridContent": "sendOrder_gridContent__ypikt",
	"paperContent": "sendOrder_paperContent__3Fswd",
	"sendOrderForm": "sendOrder_sendOrderForm__30sM-"
};