import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const ToastMessage = (message, success) => {
	const config = {
		position: toast.POSITION.TOP_RIGHT,
		autoClose: 8000
	};

	if(success){
		toast.success(message, config);
	}else {
		toast.error(message, config);
	}
};

export default ToastMessage;
